<mat-sidenav-container class="atencion-container">
  <mat-sidenav #sidenavMedico mode="side" position="end" class="atencion-sidenav" [fixedInViewport]="isMobileMedico"
    [fixedTopGap]="0" [fixedBottomGap]="0">
    <div (click)="sidenavMedico.toggle()" class="close_modal d-disable-close">X</div>
    <div (click)="sidenavMedico.toggle()" class="close_modal m-disable-close">
      <p class="arrow-left justify-content-center align-items-center d-inline-flex mb-4">
        <span class="material-icons">keyboard_arrow_left</span> Atras
      </p>
    </div>
    <div class="container nav-right-atencion">
      <div class="row avatar-row">
        <div class="col-7 col-md-7 col-xl-7">
          <h1 class="titulo-medico">Dato del Médico</h1>
        </div>
        <div class="col-5 col-md-5 col-xl-5">
          <button class="btn btn-outline-azul" (click)="modalCalendario()">Ver Horario</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">

          <form class="form-size" [formGroup]="formpersona">
            <h3 class="titulo-p-personales">Datos personales</h3>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="tipoDocumento">Tipo de documento</label>
                <select id="tipoDocumento" formControlName='tipoDocumento' class="form-control"
                  [ngClass]="{'alert-danger' : formpersona.get('tipoDocumento').invalid }">
                  <option selected>Choose...</option>
                  <option value="1">DNI</option>
                  <option value="2">CE</option>
                </select>
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="numeroDocumento">Número de documento</label>
                <input type="text" class="form-control"
                  [ngClass]="{'alert-danger' : formpersona.get('numeroDocumento').invalid }"
                  formControlName='numeroDocumento' id="numeroDocumento" placeholder="Número de documento">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="nombres">Nombres</label>
                <input type="text" formControlName='nombres'
                  [ngClass]="{'alert-danger' : formpersona.get('nombres').invalid }" class="form-control" id="nombres"
                  placeholder="Nombres">
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="apellidos">Apellidos</label>
                <input type="text" formControlName='apellidos' [class.is-danger]="formpersona.get('apellidos').invalid"
                  [ngClass]="{'alert-danger' : formpersona.get('apellidos').invalid }" class="form-control"
                  id="apellidos" placeholder="Apellidos">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label>Fecha de nacimiento</label>
                <div class="input-group">
                  <input type="date" class="form-control" placeholder="yyyy-mm-dd"
                    [ngClass]="{'alert-danger' : formpersona.get('fechaNacimiento').invalid }"
                    formControlName='fechaNacimiento'>
                  <!--<div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><span
                      class="material-icons">calendar_today</span></button>
                  </div>-->
                </div>
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="sexo">Sexo</label>
                <select id="sexo" formControlName='sexo' class="form-control"
                  [ngClass]="{'alert-danger' : formpersona.get('sexo').invalid }">
                  <option selected>Choose...</option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </select>
              </div>
            </div>
          </form>

          <form class="form-size" [formGroup]="formuser">
            <h3 class="titulo-p-personales">Datos de Contacto</h3>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="email">Correo electrónico</label>
                <input type="email" formControlName="email" class="form-control" 
                  [attr.disabled]="disableEmail? true : null"
                  [ngClass]="{'alert-danger' : formuser.get('email').invalid }" id="email" placeholder="Correo">
              </div>
            </div>
          </form>

          <form class="form-size" [formGroup]="formmedico">
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="direccion">Dirección domiciliaria</label>
                <input type="text" formControlName="direccion" class="form-control"
                  [ngClass]="{'alert-danger' : formmedico.get('direccion').invalid }" id="direccion"
                  placeholder="Dirección domiciliaria">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="celular">Celular</label>
                <input type="tel" formControlName="celular" class="form-control"
                  [ngClass]="{'alert-danger' : formmedico.get('celular').invalid }" id="celular" placeholder="Celular">
              </div>
            </div>
            <h3 class="titulo-p-personales">Datos Profesionales</h3>
            <div class="form-row mb-3">
              <div class="form-group col-md-12 input-general">
                <label for="precio">Costo Consulta</label>
                <input type="text" formControlName="precio"
                  [ngClass]="{'alert-danger' : formmedico.get('precio').invalid }" class="form-control" id="precio"
                  placeholder="Costo Consulta">
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="texto">Descripción Profesional</label>
                <textarea formControlName="texto" [ngClass]="{'alert-danger' : formmedico.get('texto').invalid }"
                  class="form-control" id="texto" placeholder=""></textarea>
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="nroColegio">Colegio Médico</label>
                <input type="text" formControlName="nroColegio" class="form-control" id="nroColegio"
                  [ngClass]="{'alert-danger' : formmedico.get('nroColegio').invalid }" placeholder="Colegio Médico">
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="rne">RNE</label>
                <input type="text" formControlName="rne" class="form-control"
                  [ngClass]="{'alert-danger' : formmedico.get('rne').invalid }" id="rne" placeholder="RNE">
              </div>
            </div>
            <p class="sub-titulo">Seleccione su especialidad</p>
            <div class="form-row">
              <div class="form-group col-md-12">
                <a class="badge badge-light" *ngFor="let espe of especialidades" (click)="badgeSelection(espe)"
                  [ngClass]="{'active' : espe.idEspecialidad === idEspecialidad }">{{espe.description}}</a>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="form-group col-md-12 input-general">
                <label>Agregar Foto de la firma</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="inputGroupFile04" (change)="cargarimagen($event)">
                    <label class="custom-file-label" for="inputGroupFile04">{{imagenSelectFirma}}</label>
                  </div>
                </div>
                <mat-card>
                  <img [src]="fotoimagenFirma" id="fotoimagenFirma" alt="firma" width="200" height="200">
                </mat-card>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="form-group col-md-12 input-general">
                <label>Agregar Foto del médico</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="inputGroupFile05"
                      (change)="cargarimagenMedico($event)">
                    <label class="custom-file-label" for="inputGroupFile05">{{imagenSelectMedico}}</label>
                  </div>
                </div>
                <mat-card>
                  <img [src]="fotoimagenMedico" id="fotoimagenMedico" alt="imagen" width="200" height="200">
                </mat-card>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="form-group col-md-8 input-general">
                <p class="mb-0">Activo</p>
              </div>
              <div class="form-group col-md-4 input-general">
                <div class="onoffswitch">
                  <input type="checkbox" name="onoffswitch" id="myonoffswitch" formControlName="estado"
                    class="onoffswitch-checkbox" tabindex="0">
                  <label class="onoffswitch-label" for="myonoffswitch">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-6">
          <button class="btn btn-spacing btn-black" (click)="sidenavMedico.toggle()">Cerrar</button>
        </div>
        <div class="col-6 col-md-6">
          <button class="btn btn-spacing btn-red" (click)="validar()">Guardar</button>
        </div>
      </div>
    </div>

  </mat-sidenav>
  <mat-sidenav-content [style.margin-right]="sidenavMedico.opened ? 'auto' : 'auto'">
    <div class="container mt-container">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-6">
          <h1 class="titulo-medico">Médicos</h1>
        </div>
        <div class="col-6 col-md-4 col-xl-4 text-center">
          <button class="btn btn-black" (click)="showMedico({})">Agregar nuevo</button>
        </div>
      </div>
      <div class="row header-p-t p-table">
        <div class="col-12 col-md-12 col-xl-12 ">
          <form class="form-medico">
            <div class="form-row">
              <div class="form-group col-12 col-md-4 input-general">
                <label for="especialidad">Especialidad</label>
                <select id="especialidad" [(ngModel)]="codigoEspecialidad" class="form-control"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeEspecialidad()">
                  <option value="0" selected="selected">TODOS</option>
                  <option [value]="item.idEspecialidad" *ngFor="let item of especialidades">{{item.description}}
                  </option>
                </select>
              </div>
              <div class="form-group col-12 col-md-4 input-general">
                <label for="NombreMedico">Nombre de médico</label>
                <input type="text" class="form-control" [(ngModel)]="textoNombre" [ngModelOptions]="{standalone: true}"
                  id="NombreMedico" placeholder="Ingrese al menos 4 caracteres" (keyup)="onChangeNonbreMedico($event)">
              </div>
              <div class="form-group col-12 col-md-4 input-general">
                <label for="NombreMedico">DNI de médico</label>
                <input type="text" class="form-control" [(ngModel)]="textodni" [ngModelOptions]="{standalone: true}"
                  id="DNIMedico" placeholder="Ingrese al menos 4 caracteres" (keyup)="onChangeDniMedico($event)">
              </div>
            </div>
          </form>
          <ng-container *ngTemplateOutlet="tabAtencion"></ng-container>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #tabAtencion>
  <div class="card-header border-0 tabla-titulo font-weight-bold">
    <div class="row">
      <div class="col-6 col-md-6 col-xl-6 pr-0 pl-0">
        <p class="mr-p mb-0">Médico</p>
      </div>
      <div class="col-6 col-md-2 col-xl-2 pr-0">
        <p class="mb-0">Activo</p>
      </div>
      <div class="col-6 col-md-2 col-xl-3 text-center  pl-0 pr-0">
      </div>
    </div>
  </div>

  <div class="scroll-container">
    <div *ngFor="let medico of medicos">
      <!-- <div *ngIf="codigoEspecialidad === medico.especialidad && medico.nombre.toUpperCase().search(textoNombre.toUpperCase()) > -1"> -->
      <div class="card mb-3 tabla-titulo">
        <div class="card-body">
          <div class="row align-items-center d-flex justify-content-center">
            <div class="col-6 col-md-6 col-xl-6 pr-0 pl-0 d-inline-flex">
              <img [src]="medico.imagen" class="mr-2 img-perfil" alt="perfil">
              <p>Dr(a). {{medico.nombre}}</p>
            </div>
            <div class="col-6 col-md-2 col-xl-2 pr-0">
              <div class="onoffswitch">
                <input type="checkbox" name="onoffswitch" [checked]="medico.estado" class="onoffswitch-checkbox"
                  id="myonoffswitch" tabindex="0">
                <label class="onoffswitch-label" for="myonoffswitch">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
            <div class="col-6 col-md-3 col-xl-3 text-center  pl-0 pr-0">
              <button class="btn btn-outline-azul" (click)="showMedico(medico)">Ver detalle</button>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>

</ng-template>