import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EventObject, FullCalendarOptions} from "ngx-fullcalendar";
@Component({
  selector: 'app-modal-maestro',
  templateUrl: './modal-maestro.component.html',
  styleUrls: ['./modal-maestro.component.scss']
})
export class ModalMaestroComponent implements OnInit {
  options: FullCalendarOptions;
  events: EventObject[];
  currentDate = new Date();
  arrayHeader = { day: 'numeric', weekday: 'long', omitCommas: true  };
  constructor(
    public dialogRef: MatDialogRef<ModalMaestroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.options = {

      defaultDate: this.currentDate,
      editable: true,
      showNonCurrentDates: true,
      header: {
        center: 'title',
        left: 'today prev,next',
        right: 'month, agendaWeek, agendaDay'
      },
      titleFormat: {
        month: 'long',
        year: 'numeric'
      },
      
      minTime: '06:00:00',
      maxTime: '18:00:00',
      timeZone: 'America/Lima',
      locale: 'es',
      navLinks: false,
      defaultView: 'agendaWeek',
      allDaySlot: false,
      columnHeaderFormat: this.arrayHeader
    };

    this.events = [
      {id: '1', title: 'My Birthday', allDay: false},
      {id: '4', title: 'prueba2', backgroundColor: 'red', start: '2020-06-29T12:00:00', end: '2020-06-29T14:00:00'},
      {id: '5', title: 'prueba3', backgroundColor: 'blue', start: '2020-06-29T12:00:00', end: '2020-06-29T14:00:00'},
      {id: '6', title: 'prueba4', backgroundColor: 'green', start: '2020-06-29T14:00:00', end: '2020-06-29T16:00:00'},
      {id: '7', title: '', backgroundColor: 'green', textColor: 'white'  , start: '2020-06-30T08:00:00', end: '2020-06-30T08:10:00'},
      {id: '8', title: '', backgroundColor: 'green', textColor: 'white'  , start: '2020-06-30T08:10:00', end: '2020-06-30T08:20:00'},
      {id: '9', title: '', backgroundColor: 'green', textColor: 'white'  , start: '2020-06-30T08:20:00', end: '2020-06-30T08:30:00'},
      {id: '10', title: '', backgroundColor: 'green', textColor: 'white'  , start: '2020-06-30T08:30:00', end: '2020-06-30T08:40:00'},
      {id: '11', title: '', backgroundColor: 'green', textColor: 'white'  , start: '2020-06-30T08:40:00', end: '2020-06-30T08:50:00'}
    ];
  }

  clicEvento(ec: any){
    ec.el.style.backgroundColor = 'yellow';
    const Fecha = this.events.filter((product: any) =>
      product.id === ec.event.id);

  }

  closeDialog() {
    this.dialogRef.close();
  }
}
