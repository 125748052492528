<mat-sidenav-container class="atencion-container">
  <mat-sidenav #sidenavCita mode="side" position="end" class="cita-sidenav" [fixedInViewport]="isMobileCita"
    [fixedTopGap]="0" [fixedBottomGap]="0">
    <div (click)="sidenavCita.toggle()" class="close_modal d-disable-close">X</div>
    <div (click)="sidenavCita.toggle()" class="close_modal m-disable-close">
      <p class="arrow-left justify-content-center align-items-center d-inline-flex mb-4">
        <span class="material-icons">keyboard_arrow_left</span> Atras
      </p>
    </div>
    <div class="container nav-right-atencion">
      <div class="row avatar-row">
        <div class="col-12 col-md-12">
          <h1 class="titulo-medico">
            {{titleModal}}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">

          <form class="form-size" [formGroup]="poolFG">

            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="title">
                  Título
                </label>
                <input type="text" formControlName="title" class="form-control" id="title" placeholder="Título">
              </div>
            </div>

            <div class="form-row">
              <label>
                Agregar Preguntas
              </label>
              <div class="form-group col-md-12 input-general">
                <input type="text" formControlName="question" class="form-control" id="question1"
                  placeholder="Pregunta">
              </div>
              <label>
                Tipo
              </label>
              <div class="form-group col-md-12 input-general">
                <select formControlName="type" class="form-control">
                  <option *ngFor="let type of typeSelect" [value]="type.value">
                    {{type.name}}</option>

                </select>
              </div>
              <div class="form-group col-md-12 input-general">
                <button type="button" (click)="addQuestion()" class="btn btn-primary">
                  <i class="fa fa-plus"></i> </button>
              </div>
            </div>

            <div *ngFor="let question of questions; let iQuestion = index">
              <div class="form-row">
                <div class="col-md-11">
                  <div class="card">
                    <div class="card-body">
                      <p class="tabla-subtitulo c-black">{{question.question}} Tipo: {{question.type}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 justify-content-center align-content-center">
                  <button type="button" (click)="quitarpregunta(question)" class="btn btn-danger btn-sm mt-3">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-row mt-3 mb-3">
                <div class="form-group col-md-8 input-general">
                  <p class="mb-0">Activo</p>
                </div>
                <div class="form-group col-md-4 input-general">
                  <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" formControlName="status" class="onoffswitch-checkbox"
                      id="myonoffswitch" tabindex="0">
                    <label class="onoffswitch-label" for="myonoffswitch">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-6">
          <button class="btn btn-spacing btn-black" (click)="sidenavCita.toggle()">
            Cerrar
          </button>
        </div>
        <div class="col-6 col-md-6">
          <button (click)="guardar()" class="btn btn-spacing btn-red">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [style.margin-right]="'auto'">
    <!--    <div class="container mt-container">-->
    <div class="container mt-3">
      <div class="row p-table">
        <div class="col-6 col-md-8">
          <h1 class="titulo-medico">Encuestas</h1>
        </div>
        <div class="col-6 col-md-4 text-center">
          <!--          (click)="showCita({})"-->
          <button type="button" (click)="newPool()" class="btn btn-black btn-sm">
            Agregar nuevo
          </button>
        </div>
      </div>
      <div class="row header-p-t p-table mt-2">
        <div class="col-12 col-md-12 col-xl-12 ">
          <ng-container *ngTemplateOutlet="tabEncuesta"></ng-container>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #tabEncuesta>
  <div class="card-header border-0 tabla-titulo font-weight-bold">
    <div class="row">
      <div class="col-3 col-md-3 col-xl-3">
        <p class="mb-0">Título</p>
      </div>
      <div class="col-3 col-md-4 col-xl-4">
        <p class="mb-0">Preguntas</p>
      </div>
      <div class="col-3 col-md-2 col-xl-2">
        <p class="mb-0">Estado</p>
      </div>
      <div class="col-3 col-md-3 col-xl-3 text-center  pl-0 pr-0">
        <p class="mb-0">Acciones</p>
      </div>
    </div>
  </div>
  <div class="scroll-container">
    <div class="card mb-3 tabla-titulo" *ngFor="let encuesta of encuestas;index as iPoll">
      <div class="card-body">
        <div class="row align-items-center d-flex justify-content-center">
          <div class="col-6 col-md-3 col-xl-3">
            <p>{{encuesta.title}}</p>
          </div>
          <div class="col-6 col-md-4 col-xl-4">
            <ul>
              <li *ngIf="encuesta.question1 != null">{{encuesta.question1}}</li>
              <li *ngIf="encuesta.question2 != null">{{encuesta.question2}}</li>
              <li *ngIf="encuesta.question3 != null">{{encuesta.question3}}</li>
              <li *ngIf="encuesta.question4 != null">{{encuesta.question4}}</li>
              <li *ngIf="encuesta.question5 != null">{{encuesta.question5}}</li>
            </ul>
          </div>
          <div class="col-6 col-md-2 col-xl-2">
            <div *ngIf="encuesta.status; else desactive">
              <h2>
                <span class="badge badge-success">Activo</span>
              </h2>
            </div>
            <ng-template #desactive>
              <h2>
                <span class="badge badge-light">Desactivado</span>
              </h2>
            </ng-template>
          </div>
          <div class="col-6 col-md-3 col-xl-3 text-center  pl-0 pr-0">
            <button class="btn btn-outline-azul" (click)="viewQuestion(iPoll)">Editar</button>
            |
            <button type="btn" (click)="deleteQuestion(encuesta.idEncuesta)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>