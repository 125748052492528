import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { EnfermedadesService } from '@services/mysql/enfermedades.service';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-enfermedades',
  templateUrl: './enfermedades.component.html',
  styleUrls: ['./enfermedades.component.scss']
})
export class EnfermedadesComponent implements OnInit {
  @ViewChild('sidenavCita') sidenavCita: MatSidenav;
  isMobileCita = true;
  especialidades: any[] = [];
  form: FormGroup;
  constructor(
    //private especialidadesService: EnfermedadesService,
    private EnfermedadesService: EnfermedadesService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private loading: LoadingService
    ) {
    this.detectorCita();
    // this.especialidadesService.getAll().valueChanges().subscribe(
    //   (data: any) => {
    //     this.especialidades = data || [];
    //   }
    // );

    this.form = new FormGroup({
      idEnfermedad: new FormControl(''),
      description: new FormControl(''),
      status: new FormControl(true)
    });
  }

  ngOnInit(): void {
    this.loading.show();
    this.cargarDatos()
  }

  async cargarDatos(){
    let data = await this.EnfermedadesService.listar()
    this.especialidades = data.content
    this.loading.hide();
  }

  showCita(item) {
    this.form.patchValue({
      idEnfermedad: item.idEnfermedad,
      description: item.description || '',
      status: item.status == 1 ? true : false
    });
    this.sidenavCita.toggle()

  }

  detectorCita() {
    const isdeviceMobile = this.deviceService.isMobile();
    isdeviceMobile === true ? this.isMobileCita = false : this.isMobileCita = true;
  }

  async guardar(){
    if ( this.form.value.description ) {
      this.loading.show();
      this.form.value.status = this.form.value.status == true ? 1 : 0
      if ( this.form.value.idEnfermedad === undefined ) {
        this.form.value.idEnfermedad = 0
        await this.EnfermedadesService.crear(this.form.value).then(
          () => {
            this.sidenavCita.toggle()
          }
        ).catch(
          (error) => {
            console.log(error)
          }
        )
      }else{
        await this.EnfermedadesService.actualizar(this.form.value).then(
          () => {
            this.sidenavCita.toggle()
          }
        ).catch(
          (error) => {
            console.log(error)
          }
        )
      }
    }
    this.cargarDatos()
  }

}