import { Component, Inject, OnInit } from '@angular/core';
import { PeriodicElement, PeriodicElementV1 } from "@shared/interfaces/user";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MedicoService } from '@services/core/medico.service';
import { SmedicoService } from '@services/core/smedico.service';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-modal-horario',
  templateUrl: './modal-horario.component.html',
  styleUrls: ['./modal-horario.component.scss']
})
export class ModalHorarioComponent implements OnInit {
  dataSource1: PeriodicElementV1[] = [];
  displayedColumns: string[] = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
  cronograma: any = [];
  mscronograma: any = [];
  shorario: any = { listHorario: [] };
  savecronograma: any = [];
  constructor(
    private medicoService: MedicoService,
    private smedicoService: SmedicoService,
    public dialogRef: MatDialogRef<ModalHorarioComponent>,
    private loading: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.medicoService.getCronograma(data.uid).valueChanges().subscribe(
      (crono) => {
        if (crono && crono.length > 0) {
          this.cronograma = crono;
        } else {
          this.cronograma = this.getCronograma();
        }
        this.dataSource1 = this.convertToPeriodicElement(this.cronograma);
      }
    );
    this.smedicoService.listarhorario(data.idMedico).then(
      (crono) => {
        this.shorario.listHorario = crono;

        //this.dataSource1 = this.convertToPeriodicElement(this.cronograma);

      }
    );
  }

  getCronograma() {
    const cronograma = [];
    const hora = [];
    for (let hor = 0; hor < 24; hor++) {
      for (let min = 0; min < 6; min++) {
        const element: any = {};
        element.hora = this.redondear(hor) + ':' + this.redondear(min * 10);
        element[0] = { status: '' };
        element[1] = { status: '' };
        element[2] = { status: '' };
        element[3] = { status: '' };
        element[4] = { status: '' };
        element[5] = { status: '' };
        element[6] = { status: '' };
        cronograma.push(element);
      }
    }
    return cronograma;
  }

  convertToPeriodicElement(cronograma: any) {
    const crono: PeriodicElementV1[] = [];
    cronograma.forEach(element => {
      const semana = {
        Lunes: { name: 'Lunes', hora: element.hora, status: element[1].status, uid: 1 },
        Martes: { name: 'Martes', hora: element.hora, status: element[2].status, uid: 2 },
        Miercoles: { name: 'Miercoles', hora: element.hora, status: element[3].status, uid: 3 },
        Jueves: { name: 'Jueves', hora: element.hora, status: element[4].status, uid: 4 },
        Viernes: { name: 'Viernes', hora: element.hora, status: element[5].status, uid: 5 },
        Sabado: { name: 'Sabado', hora: element.hora, status: element[6].status, uid: 6 },
        Domingo: { name: 'Domingo', hora: element.hora, status: element[0].status, uid: 0 },
      };
      crono.push(semana);
    });
    return crono;
  }
  redondear(num: number) {
    return ('0' + num).length > 2 ? num : ('0' + num);
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  eventoHorario(event: any, bgHorario: any) {
    //this.eventoHorarioms(event,bgHorario);

    if (event.status === 'online') {
      event.status = '';

    } else {
      event.status = 'online';
    }
    const index = this.cronograma.findIndex(element => element.hora === event.hora);
    this.cronograma[index][event.uid].status = event.status;


    let diasemh;
    if (event.uid == 0) {
      diasemh = "Domingo";
    } else if (event.uid == 1) {
      diasemh = "Lunes";
    } else if (event.uid == 2) {
      diasemh = "Martes";
    } else if (event.uid == 3) {
      diasemh = "Miercoles";
    } else if (event.uid == 4) {
      diasemh = "Jueves";
    } else if (event.uid == 5) {
      diasemh = "Viernes";
    } else if (event.uid == 6) {
      diasemh = "Sabado";
    }
    const diah = {
      dia: diasemh,
      hora: event.hora,
      estado: 'online',
      idMedico: this.data.idMedico
    }

    if(this.shorario.listHorario.length ==0){
      this.shorario.listHorario.push(diah);
    }

    else if (this.shorario.listHorario.length >=1) {
        if ((this.shorario.listHorario.findIndex(horariomed => horariomed.dia === diah.dia && horariomed.hora === diah.hora))>-1) {
          let indice = this.shorario.listHorario.findIndex(horariomed => horariomed.dia === diah.dia && horariomed.hora === diah.hora);
          this.RemoveElementFromArray(indice)
        } else {
          this.shorario.listHorario.push(diah);
        }
      



    }

    //this.shorario.listHorario.indexOf(diah) > -1
    //console.log(this.cronograma)
  }
  removeItemFromArr(arr: string[], item: any) {
    const i: number = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
  }
  RemoveElementFromArray(element: number) {
    this.shorario.listHorario.splice(element, 1);

  }
  /*removeTask(data:any){
    const index: number = this.list.indexOf(data);
    if (index !== -1) {
        this.list.splice(index, 1);
    } 
  }*/
  /*eventoHorarioms(event: any, bgHorario: any) {
    if (event.status === 'online') {
      event.status = '';
    } else {
      event.status = 'online';
    }
    const index = this.cronograma.findIndex(element => element.hora === event.hora);*/
  //const index2 = this.dataSource1.findIndex(element => element === event);
  //console.log(index2)
  /*this.cronograma[index][event.uid].status = event.status;
  let diasemh;
  if (event.uid == 0) {
    diasemh = "Domingo";
  } else if (event.uid == 1) {
    diasemh = "Lunes";
  } else if (event.uid == 2) {
    diasemh = "Martes";
  } else if (event.uid == 3) {
    diasemh = "Miercoles";
  } else if (event.uid == 4) {
    diasemh = "Jueves";
  } else if (event.uid == 5) {
    diasemh = "Viernes";
  } else if (event.uid == 6) {
    diasemh = "Sabado";
  }
  const diah = {
    dia: diasemh, hora: event.hora, estado: event.status, idMedico: this.data.idMedico
  }*/
  //const shorario = {

  /*Lunes: 
  Martes: { name: 'Martes', hora: element.hora, status: element[2].status, uid: 2 },
  Miercoles: { name: 'Miercoles', hora: element.hora, status: element[3].status, uid: 3 },
  Jueves: { name: 'Jueves', hora: element.hora, status: element[4].status, uid: 4 },
  Viernes: { name: 'Viernes', hora: element.hora, status: element[5].status, uid: 5 },
  Sabado: { name: 'Sabado', hora: element.hora, status: element[6].status, uid: 6 },
  Domingo: { name: 'Domingo', hora: element.hora, status: element[0].status, uid: 0 },*/
  //}
  //this.shorario.listHorario.push(diah)
  //this.savecronograma.push(this.dataSource1[index2][event]);
  //console.log(this.shorario)
  //console.log(this.savecronograma)
  guardar() {
    this.loading.show();
    //console.log(this.shorario)
    this.medicoService.setCronograma(this.data.uid, this.cronograma).then(
      () => {
      }
    );
    this.smedicoService.eliminarhorariofrommedico(this.data.idMedico).then(
      () => {
        this.smedicoService.registrarhorario(this.shorario).then(
          () => {
            this.loading.hide();
            alert('resgistros actualizados');
          }
        );
      }
    );

  }
}

/*guardar() {
  this.medicoService.setCronograma(this.data.uid, this.cronograma).then(
    () => {
      alert('resgistros actualizados');
    }
  );
}*/


//}
