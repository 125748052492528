export const environment = {
  production: false,
  rootPassword: '10021985',
  firebaseConfig : {
    apiKey: "AIzaSyDuTVY0xHlKyoQjsk12tEDMj9f3kepv4I4",
    authDomain: "telemedicina-d2a10.firebaseapp.com",
    databaseURL: "https://telemedicina-d2a10-default-rtdb.firebaseio.com",
    projectId: "telemedicina-d2a10",
    storageBucket: "telemedicina-d2a10.appspot.com",
    messagingSenderId: "769822510965",
    appId: "1:769822510965:web:6147e91a20e78d1cf20d3c",
    measurementId: "G-T5G4DJ82ND"
  },
  zoom: {
    token: "a1lQTll3eDdUbjJGV2M1TFo3QkFMZ2Exd3JaUDE5eEt0QUZvMGpveTJJdWQ1ZXB1WEpmVlVo",
    meeting: "https://video.midoc-enlinea.com/zoom/meeting"
  },
  years: 90
};
//export const apiServer = "http://localhost:8080/midoc/v1/"
export const apiServer = "http://178.128.158.138:8085/midoc/v1/"
