import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { ModalLoginComponent } from '../../modals/modal-login/modal-login.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss']
})
export class HeaderHomeComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  deviceInfo = null;
  hasBackdrop = false;
  position = 'start';
  marginAuto = '200px';
  isMobile;
  isTablet;
  fecha: Date;
  optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  constructor(public dialogLogin: MatDialog,
    private deviceService: DeviceDetectorService,
    public router: Router) {
    this.fecha = new Date();
    this.openNavigation();
  }

  ngOnInit(): void {
  }

  openNavigation() {
    setTimeout(() => {
      this.detectorHeader();
    }, 100);
  }

  close() {
    this.marginAuto = '0';
    this.hasBackdrop = true;
    this.position = 'end';
    this.sidenav.toggle();
  }

  openModalLogin(): void {
    this.dialogLogin.open(ModalLoginComponent, {
      width: '400px',
      height: '290px'
    });
  }

  inicioNav() {
    this.router.navigate(['/sitio/medico']);
    this.detectorHeader();
  }

  opcionAdmin(codHeader: any) {
    switch (codHeader) {
      case 0:
        this.router.navigate(['/sitio/medico']);
        break;

      case 1:
        this.router.navigate(['/sitio/paciente']);
        break;
      case 2:
        this.router.navigate(['/sitio/tipoatencion']);
        break;
      case 3:
        this.router.navigate(['/sitio/pago']);
        break;
      case 4:
        this.router.navigate(['/sitio/empresa']);
        break;
      case 5:
        this.router.navigate(['/sitio/admin']);
        break;
      case 6:
        this.router.navigate(['/sitio/especialidades']);
        break;
      case 7:
        this.router.navigate(['/sitio/enfermedades']);
        break;
      case 8:
        this.router.navigate(['/sitio/reporteMedico']);
        break;
      case 9:
        this.router.navigate(['/sitio/reporteMedicoEstadoAtenciones']);
        break;
      case 10:
        this.router.navigate(['/sitio/encuesta']);
        break;
      case 11:
        this.router.navigate(['/sitio/publicaciones']);
        break;
      case 12:
        this.router.navigate(['/sitio/reporte-pagos']);
        break;
      case 13:
        this.router.navigate(['/sitio/encuestas-atencion']);
        break;
    }
    if (this.isMobile === true || this.isTablet === true) { this.sidenav.toggle(); }
  }

  detectorHeader() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isMobile === true ? this.sidenav.toggle(false) : this.isTablet === true ? this.sidenav.toggle(false) : this.sidenav.toggle(true);
  }

}
