import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@security/login/login.component';
import {HeaderHomeComponent} from '@template/navigation/header-home/header-home.component';
import {MedicoComponent} from '@pages/medico/medico.component';
import {PacienteComponent} from '@pages/paciente/paciente.component';
import {EmpresaComponent} from '@pages/empresa/empresa.component';
import {PagoComponent} from '@pages/pago/pago.component';
import {AdminComponent} from '@pages/admin/admin.component';
import { RptMedicosComponent } from '@pages/reportes/medicos/rptmedicos.component';
import { AuthenticationGuard } from '@services/authentication.guard';
import { Rpt01Component } from '@pages/reportes/reporte01/rpt01.component';
import {ReportePagosComponent} from '@pages/reportes/reporte-pagos/reporte-pagos.component'
import { EspecialidadesComponent } from '@pages/especialidades/especialidades.component'
import { EnfermedadesComponent } from '@pages/enfermedades/enfermedades.component'
import { CitaComponent } from '@pages/cita/cita.component'
import { EncuestaComponent } from '@pages/encuesta/encuesta.component'
import { PublicacionesComponent } from '@pages/publicaciones/publicaciones.component'
import { EncuestaatencionComponent } from '@pages/encuestaatencion/encuestaatencion.component'

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sitio',
    component: HeaderHomeComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'medico',
        pathMatch: 'full'
      },
      {
        path: 'encuesta',
        component: EncuestaComponent
      },
      {
        path: 'publicaciones',
        component: PublicacionesComponent
      },
      {
        path: 'medico',
        component:  MedicoComponent
      },
      {
        path: 'paciente',
        component:  PacienteComponent
      },
      {
        path: 'tipoatencion',
        component: CitaComponent
      },

      {
        path: 'especialidades',
        component: EspecialidadesComponent
      },
      {
        path: 'enfermedades',
        component: EnfermedadesComponent
      },
      {
        path: 'pago',
        component: PagoComponent
      },
      {
        path: 'empresa',
        component: EmpresaComponent
      },
      {
        path: 'admin',
        component: AdminComponent
      },
      {
        path: 'reporteMedico',
        component: RptMedicosComponent
      },
      {
        path: 'reporteMedicoEstadoAtenciones',
        component: Rpt01Component
      },
      {
        path: 'reporte-pagos',
        component: ReportePagosComponent
      },
      {
        path: 'encuestas-atencion',
        component: EncuestaatencionComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
