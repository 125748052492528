import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { EspecialidadesService } from '@services/mysql/especialidades.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-especialidades',
  templateUrl: './especialidades.component.html',
  styleUrls: ['./especialidades.component.scss']
})
export class EspecialidadesComponent implements OnInit {

  @ViewChild('sidenavCita') sidenavCita: MatSidenav;

  especialidades
  formespecialidad: FormGroup
  isMobileCita = true

  constructor(
    private EspecialidadesService: EspecialidadesService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private loading: LoadingService

  ) {
    this.detectorCita();
    this.formespecialidad = new FormGroup({
      idEspecialidad: new FormControl(''),
      description: new FormControl(''),
      status: new FormControl(true)
    });
  }

  async ngOnInit() {
    this.loading.show();
    this.cargarEspecialidades()
  }

  async cargarEspecialidades() {
    let data = await this.EspecialidadesService.listarEspecialidades()
    this.especialidades = data
    this.loading.hide();
  }

  detectorCita() {
    const isdeviceMobile = this.deviceService.isMobile()
    isdeviceMobile === true ? this.isMobileCita = false : this.isMobileCita = true
  }

  form(item) {
    this.formespecialidad.patchValue({
      idEspecialidad: item.idEspecialidad,
      description: item.description || '',
      status: item.status == 1 ? true : false
    });
    this.sidenavCita.toggle()
  }

  async guardar() {
    this.loading.show();
    if (this.formespecialidad.value.description) {
      this.formespecialidad.value.status = this.formespecialidad.value.status == true ? 1 : 0
      if (this.formespecialidad.value.idEspecialidad === undefined) {
        this.formespecialidad.value.idEspecialidad = 0
        await this.EspecialidadesService.crear(this.formespecialidad.value).then(
          () => {
            this.sidenavCita.toggle()
          }
        ).catch(
          (error) => {
            console.log(error)
          }
        )
      } else {
        await this.EspecialidadesService.actualizar(this.formespecialidad.value).then(
          () => {
            this.sidenavCita.toggle()
          }
        ).catch(
          (error) => {
            console.log(error)
          }
        )
      }
    }
    this.cargarEspecialidades()
  }
  async delete(idEspecialidad) {
    if (confirm("¿Está seguro de eliminar?")) {
      this.loading.show();
      await this.EspecialidadesService.eliminar(idEspecialidad).then(
        () => {
          this.cargarEspecialidades()
        }
      ).catch(
        (error) => {
          console.log(error)
        }
      )
      alert("Eliminado");
    } else {
      alert("Cancelado");
    }
  }

}