import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Pago } from '@services/model/pago.modelo';
import { Empresa } from '@services/model/empresa.modelo';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private angularFireDatabase: AngularFireDatabase) { }

  getPago(){
   return this.angularFireDatabase.object('/pago');
  }
  setPago(pago: Pago){
    return this.angularFireDatabase.object('/pago').set(pago);
  }
  getEmpresa(){
    return this.angularFireDatabase.object('/empresa');
   }
   setEmpresa(pago: Empresa){
     return this.angularFireDatabase.object('/empresa').set(pago);
   }
}
