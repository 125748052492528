<mat-sidenav-container class="atencion-container">
    <mat-sidenav #sidenavCita mode="side" position="end" class="cita-sidenav"
                 [fixedInViewport]="isMobileCita" [fixedTopGap]="0"
                 [fixedBottomGap]="0">
      <div (click)="sidenavCita.toggle()" class="close_modal d-disable-close">X</div>
      <div (click)="sidenavCita.toggle()" class="close_modal m-disable-close">
        <p class="arrow-left justify-content-center align-items-center d-inline-flex mb-4">
          <span class="material-icons">keyboard_arrow_left</span> Atras</p>
      </div>
      <div class="container nav-right-atencion">
        <div class="row avatar-row">
          <div class="col-12 col-md-12">
            <h1 class="titulo-medico">Tipo de Atención</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <form class="form-size" [formGroup]="form">
              <div class="form-row">
                <div class="form-group col-md-12 input-general">
                  <label for="title">Título</label>
                  <input type="text" formControlName='title' class="form-control" id="title" placeholder="Título">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12 input-general">
                  <label for="description">Descripción</label>
                  <textarea class="form-control" formControlName='description' id="description"></textarea>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row mt-3 mb-3">
                  <div class="form-group col-md-8 input-general">
                    <p class="mb-0">Activo</p>
                  </div>
                  <div class="form-group col-md-4 input-general">
                    <div class="onoffswitch">
                      <input type="checkbox" name="onoffswitch" formControlName="status" class="onoffswitch-checkbox"
                             id="myonoffswitch" tabindex="0">
                      <label class="onoffswitch-label" for="myonoffswitch">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-6">
            <button class="btn btn-spacing btn-black" (click)="sidenavCita.toggle()">Cerrar</button>
          </div>
          <div class="col-6 col-md-6">
            <button class="btn btn-spacing btn-red" (click)="guardar()">Guardar</button>
          </div>
        </div>
      </div>
  
    </mat-sidenav>
    <mat-sidenav-content [style.margin-right]="sidenavCita.opened ? 'auto' : 'auto'">
      <div class="container mt-container">
        <div class="row p-table">
          <div class="col-6 col-md-8 col-xl-8">
            <h1 class="titulo-medico">Tipo de atención</h1>
          </div>
          <div class="col-6 col-md-4 col-xl-4 text-center">
            <button class="btn btn-black" (click)="showCita({})">Agregar nuevo</button>
          </div>
        </div>
        <div class="row header-p-t p-table">
          <div class="col-12 col-md-12 col-xl-12 ">
            <ng-container *ngTemplateOutlet="tabAtencion"></ng-container>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  
  <ng-template #tabAtencion>
    <div class="card-header border-0 tabla-titulo font-weight-bold">
      <div class="row">
        <div class="col-3 col-md-3 col-xl-3">
          <p class="mb-0">Título</p>
        </div>
        <div class="col-3 col-md-4 col-xl-4">
          <p class="mb-0">Descripción</p>
        </div>
        <div class="col-3 col-md-2 col-xl-2">
          <p class="mb-0">Estado</p>
        </div>
        <div class="col-3 col-md-3 col-xl-3 text-center  pl-0 pr-0">
        </div>
      </div>
    </div>
    <div class="scroll-container">
      <div class="card mb-3 tabla-titulo" *ngFor="let tipoate of tipoatenciones">
        <div class="card-body">
          <div class="row align-items-center d-flex justify-content-center">
            <div class="col-6 col-md-3 col-xl-3">
              <p>{{tipoate.title}}</p>
            </div>
            <div class="col-6 col-md-4 col-xl-4">
              <p>{{tipoate.description}}</p>
            </div>
            <div class="col-6 col-md-2 col-xl-2">
              <div class="onoffswitch">
                <input type="checkbox" name="onoffswitch" [checked]="tipoate.status" class="onoffswitch-checkbox"
                       id="myonoffswitch" tabindex="0">
                <label class="onoffswitch-label" for="myonoffswitch">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
            <div class="col-6 col-md-3 col-xl-3 text-center  pl-0 pr-0">
              <button class="btn btn-outline-azul" (click)="showCita(tipoate)">Ver detalle</button>
              |
            <button type="btn" (click)="delete(tipoate.idTipoAtencion)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  