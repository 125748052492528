<!--<div class="my-overlay">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>-->
<mat-sidenav-container class="atencion-container">

  <mat-sidenav #sidenavPago mode="side" position="end" class="atencion-sidenav" [fixedInViewport]="isMobileMedico"
    [fixedTopGap]="0" [fixedBottomGap]="0">

    <div (click)="sidenavPago.toggle()" class="close_modal d-disable-close">X</div>
    <div (click)="sidenavPago.toggle()" class="close_modal m-disable-close">
      <p class="arrow-left justify-content-center align-items-center d-inline-flex mb-4">
        <span class="material-icons">keyboard_arrow_left</span> Atras
      </p>
    </div>
    <div class="container nav-right-atencion">
      <div class="row avatar-row">
        <div class="col-7 col-md-7 col-xl-7">
          <h1 class="titulo-medico">Dato del Pago</h1>
        </div>
        <div class="col-5 col-md-5 col-xl-5">
          <!--          <button class="btn btn-outline-azul">Ver Horario</button>-->
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <form class="form-size">
            <h3 class="titulo-p-personales">Datos Profesionales</h3>

            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label>Fecha</label>
                <input type="text" class="form-control" placeholder="Fecha" [value]="pagoSeleccionado?.fecha" disabled>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label>Médico</label>
                <input type="text" class="form-control" placeholder="Nombre del médico"
                  [value]="pagoSeleccionado?.medico.nombre" disabled>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label>Especialidad</label>
                <input type="text" class="form-control" placeholder="Especialidad"
                  [value]="pagoSeleccionado?.especialidad.description" disabled>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label>Paciente</label>
                <input type="text" class="form-control" placeholder="Nombre del paciente"
                  [value]="pagoSeleccionado?.paciente.persona.nombres +' '+ pagoSeleccionado?.paciente.persona.apellidos"
                  disabled>
              </div>
            </div>
            <div class="row row-spacing text-center" *ngIf="pagoSeleccionado?.estado === 'creada'">


              <div class="form-group col-12 col-md-12 col-xl-12">
                <label for="modelCita">Selecciona el día</label>
                <div class="input-group">
                  <input class="form-control" id="modelCita" placeholder="yyyy-mm-dd"
                    (ngModelChange)="cargarHorario($event)" name="dp" [(ngModel)]="modelCita" ngbDatepicker
                    #d="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><span
                        class="material-icons">
                        calendar_today
                      </span></button>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-12 col-xl-12" *ngIf="showHorario">
                <p class="fecha-larga">{{fechaSeleccionada.toLocaleDateString('pe-PE', optionsDate)}}</p>
                <div class="mt-3 mb-3">
                  <h4 class="texto-fecha c-rojo mb-0">Selecciona hora</h4>
                  <div class="container-tabs">
                    <div class="container-header">
                      <ul>
                        <li *ngFor="let hora of horario">
                          <span class="badge badge-light" (click)="badgeCita(hora.hora)"
                            [ngClass]="{'active' : hora.hora === horaSelecionada}">{{hora.hora}}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3" *ngIf="pagoSeleccionado?.payment === 'YAPE'">
              <div class="form-group col-md-12 input-general">
                <label>Imagen del comprobante de pago <span class="font-weight-bold">YAPE</span></label>
                <mat-card>
                  <img [src]="pagoSeleccionado?.imgyape" id="fotoimagenFirma" alt="firma" width="200" height="200">
                </mat-card>
              </div>
            </div>

            <div class="form-group col-12"
              *ngIf="pagoSeleccionado?.estado === 'creada' || pagoSeleccionado?.estado === 'cancelada' ">
              <div class="row">
                <div class="col-6">
                  <h3>Cancelar cita</h3>
                </div>
                <form [formGroup]="formcancelar">
                  <div class="col-6">
                    <div class="onoffswitch">
                      <input type="checkbox" name="onoffswitch" formControlName="cancelado" class="onoffswitch-checkbox"
                        id="myonoffswitch2" tabindex="0">
                      <label class="onoffswitch-label" for="myonoffswitch2">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="form-group col-12"
              *ngIf="pagoSeleccionado?.payment === 'YAPE' && pagoSeleccionado?.estado === 'creada'">
              <div class="row">
                <div class="col-6">
                  <h3>Aprobar cita</h3>
                </div>
                <form [formGroup]="formatencion">
                  <div class="col-6">
                    <div class="onoffswitch">
                      <input type="checkbox" name="onoffswitch" formControlName="validado" class="onoffswitch-checkbox"
                        id="myonoffswitch" tabindex="0">
                      <label class="onoffswitch-label" for="myonoffswitch">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-6">
          <button class="btn btn-spacing btn-black" (click)="sidenavPago.toggle()">Cerrar</button>
        </div>
        <div class="col-6 col-md-6"
          *ngIf="pagoSeleccionado?.estado === 'creada' || pagoSeleccionado?.estado === 'cancelada'">
          <button class="btn btn-spacing btn-red"
            (click)="guardarvalidadoatencion(pagoSeleccionado.idAtencion)">Guardar</button>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content style="margin-right: auto">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-12 col-xl-12 ">
          <h1 class="titulo-perfil">Reporte de pagos</h1>
        </div>

        <div class="col-12 col-md-12 col-xl-12">
          <mat-form-field appearance="fill" [formGroup]="range">
            <mat-label>Fecha inicial</mat-label>
            <input matInput [matDatepicker]="dpini" formControlName="start" id="inifecha"
              (dateChange)="onSelectionChange()">
            <mat-datepicker-toggle matSuffix [for]="dpini"></mat-datepicker-toggle>
            <mat-datepicker #dpini></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" [formGroup]="range">
            <mat-label>Fecha final</mat-label>
            <input matInput [matDatepicker]="dpfin" formControlName="end" (dateChange)="onSelectionChange()">
            <mat-datepicker-toggle matSuffix [for]="dpfin"></mat-datepicker-toggle>
            <mat-datepicker #dpfin></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row scroll-container" style="overflow-x: auto;">
        <div class="col-12">
          <table mat-table [dataSource]="dataSource">

            <!-- Position Column -->
            <ng-container matColumnDef="fecha">
              <th mat-header-cell *matHeaderCellDef> Fecha</th>
              <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="hora">
              <th mat-header-cell *matHeaderCellDef> Hora</th>
              <td mat-cell *matCellDef="let element"> {{element.hora}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="medico">
              <th mat-header-cell *matHeaderCellDef> Médico</th>
              <td mat-cell *matCellDef="let element"> {{element.medico.nombre}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="celular">
              <th mat-header-cell *matHeaderCellDef> Celular</th>
              <td mat-cell *matCellDef="let element"> {{element.medico.celular}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="especialidad">
              <th mat-header-cell *matHeaderCellDef> Especialidad</th>
              <td mat-cell *matCellDef="let element"> {{element.especialidad.description}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="paciente">
              <th mat-header-cell *matHeaderCellDef> Paciente</th>
              <td mat-cell *matCellDef="let element"> {{element.paciente.persona.nombres}}
                {{element.paciente.persona.apellidos}}</td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="celularPaciente">
              <th mat-header-cell *matHeaderCellDef> Celular</th>
              <td mat-cell *matCellDef="let element"> {{element.paciente.celular}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="metodoDePago">
              <th mat-header-cell *matHeaderCellDef> Método</th>
              <td mat-cell *matCellDef="let element"> {{element.payment}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="estadoDePago">
              <th mat-header-cell *matHeaderCellDef> Estado</th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.validado==1 && element.estado!='cancelada';">APROBADO</div>
                <div *ngIf="element.validado==0 && element.estado!='cancelada';">PENDIENTE</div>
                <div *ngIf="element.validado==0 && element.estado=='cancelada';">CANCELADO</div>

                <!--<ng-template #desapro>
                  PENDIENTE
                </ng-template>-->

              </td>

            </ng-container>



            <ng-container matColumnDef="detalles">
              <th mat-header-cell *matHeaderCellDef> Ver detalle</th>
              <td mat-cell *matCellDef="let element">
                <button class="btn btn-outline-azul" (click)="seleccionarPago(element)">Ver detalle</button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
          <button class="btn btn-outline-azul" (click)="exportExcel()">Export Excel</button>

        </div>

      </div>

    </div>

  </mat-sidenav-content>

</mat-sidenav-container>