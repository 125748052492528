<h2 mat-dialog-title  class="close_modal text-right close_desktop" (click)="closeDialog()"><span>x</span></h2>
<p (click)="closeDialog()" mat-dialog-title class="arrow-left close_modal justify-content-center align-items-center d-inline-flex mb-4">
  <span class="material-icons">keyboard_arrow_left</span> Atras</p>
<mat-dialog-content class="dialog-content">
  <ngx-fullcalendar  class="calendar-capitalize"
                     [events]="events"
                     (onEventClick)="clicEvento($event)" [options]="options"></ngx-fullcalendar>
</mat-dialog-content>
<mat-dialog-actions class="dialog-float">
  <button class="btn btn-red mt-2"  [mat-dialog-close]="true" >Guardar cambios</button>
</mat-dialog-actions>
