import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Enfermedad } from '@services/model/enfermedad.modelo';

@Injectable({
  providedIn: 'root'
})
export class EnfermedadesServicef {
  diseases = '/diseases';
  constructor(private angularFireDatabase: AngularFireDatabase) { }

  getAll(){
   return this.angularFireDatabase.list(this.diseases);
  }
  push(disease: Enfermedad){
    return this.angularFireDatabase.object(this.diseases + '/' + disease.uid).set(disease);
  }
  get(uid: any){
    return this.angularFireDatabase.object(this.diseases + '/' + uid);
  }
}
