import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FileUpload } from '@template/modals/modal-examen/file-upload';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private basePath = '/examenes';

  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage)
    {
    }

    pushFileToStorage(fileUpload: FileUpload, uid : string): Observable<number> {
      const filePath = `${this.basePath}/${fileUpload.file.name}`;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, fileUpload.file);
      const fecha = new Date();


      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {
            fileUpload.url = downloadURL;
            fileUpload.name = fileUpload.file.name;
            fileUpload.pacienteUid = uid;
            fileUpload.fecha = fecha.toLocaleDateString('af-NA');
            this.saveFileData(fileUpload);
          });
        })
      ).subscribe();
      return uploadTask.percentageChanges();
    }

    private saveFileData(fileUpload: FileUpload): void {
      this.db.list(this.basePath).push(fileUpload);
    }

    getFiles(numberItems): AngularFireList<FileUpload> {
      return this.db.list(this.basePath, ref =>
        ref.limitToLast(numberItems));
    }

    deleteFile(fileUpload: FileUpload): void {
      this.deleteFileDatabase(fileUpload.key)
        .then(() => {
          this.deleteFileStorage(fileUpload.name);
        })
        .catch(error => console.log(error));
    }

    private deleteFileDatabase(key: string): Promise<void> {
      return this.db.list(this.basePath).remove(key);
    }

    private deleteFileStorage(name: string): void {
      const storageRef = this.storage.ref(this.basePath);
      storageRef.child(name).delete();
    }

}
