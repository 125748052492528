import { Component, OnInit, ViewChild } from '@angular/core'
import { Encuesta } from '@services/model/encuesta.model'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { MatSidenav } from '@angular/material/sidenav';
import { EncuestaService } from '@services/mysql/encuesta.service';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss']
})
export class EncuestaComponent implements OnInit {
  @ViewChild('sidenavCita') sidenavCita: MatSidenav;
  isMobileCita = true;
  encuestas: Encuesta[] = [];
  questions: any[] = [];
  typeSelect = [{
    "name": "Texto",
    "value": "Texto"
  }, {
    "name": "Opciones (Si/No)",
    "value": "Opciones"
  },
  /* {
    "name": "Opciones con texto (Si/No + Texto)",
    "value": "OpcionesTexto"
  }, */
  {
    "name": "Calificación (1 al 10)",
    "value": "Calificacion"
  }];
  titleModal = 'Nueva encuesta'

  poolFG = new FormGroup({
    idEncuesta: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    question: new FormControl(''),
    type: new FormControl(''),
    status: new FormControl(false, Validators.required)
  });

  constructor(
    //private encuestaService: EncuestaService,
    private EncuestaService: EncuestaService,
    private loading: LoadingService

  ) {
  }

  ngOnInit(): void {
    this.loading.show();
    this.listarEncuestas()

  }


  async listarEncuestas() {
    let data = await this.EncuestaService.listar()
    this.encuestas = data.content
    this.loading.hide();
    this.poolFG.patchValue({
      type: 'Texto',
    });
  }
  addQuestion() {
    if (this.questions.length === 5) {
      alert('Solo debe haber como máximo 5 preguntas')
    } else {
      if (this.poolFG.value.question == '' || this.poolFG.value.question == null && this.poolFG.value.type == '' || this.poolFG.value.type == null) {
        alert("Complete los campos");

      } else {
        let data = { "question": this.poolFG.value.question, "type": this.poolFG.value.type };
        this.questions.push(data);
        this.poolFG.patchValue({
          question: '',
        });
      }
    }


  }

  quitarpregunta(question) {
    const encontrado = this.questions.indexOf(question);
    if (encontrado !== -1) {
      this.questions.splice(encontrado, 1);
    }
  }
  async guardar() {
    //if (this.poolFG.value.title != null && this.poolFG.value.title != '') {
    if (this.questions.length == 0) {
      alert("Debe agregar al menos una pregunta")
    } else {
      this.loading.show();
      this.poolFG.value.status = this.poolFG.value.status == true ? 1 : 0
      if (this.poolFG.value.idEncuesta === null) {
        this.poolFG.value.idEncuesta = 0
        let encuesta: any = {
          "idEncuesta": 0,
          "question1": this.questions[0]?.question,
          "question2": this.questions[1]?.question,
          "question3": this.questions[2]?.question,
          "question4": this.questions[3]?.question,
          "question5": this.questions[4]?.question,
          "type1": this.questions[0]?.type,
          "type2": this.questions[1]?.type,
          "type3": this.questions[2]?.type,
          "type4": this.questions[3]?.type,
          "type5": this.questions[4]?.type,
          "status": this.poolFG.value.status,
          "title": this.poolFG.value.title
        }
        await this.EncuestaService.crear(encuesta).then(
          () => {
            this.sidenavCita.toggle()
            this.listarEncuestas()
          }
        ).catch(
          (error) => {
            console.log(error)
          }
        )
      } else {
        let encuesta: any = {
          "idEncuesta": this.poolFG.value.idEncuesta,
          "question1": this.questions[0]?.question,
          "question2": this.questions[1]?.question,
          "question3": this.questions[2]?.question,
          "question4": this.questions[3]?.question,
          "question5": this.questions[4]?.question,
          "type1": this.questions[0]?.type,
          "type2": this.questions[1]?.type,
          "type3": this.questions[2]?.type,
          "type4": this.questions[3]?.type,
          "type5": this.questions[4]?.type,
          "status": this.poolFG.value.status,
          "title": this.poolFG.value.title
        }
        await this.EncuestaService.actualizar(encuesta).then(
          () => {
            this.sidenavCita.toggle()
            this.listarEncuestas()
          }
        ).catch(
          (error) => {
            console.log(error)
          }
        )
      }
    }
    /*} else {
      alert("Ingrese un Título")

    }*/


  }

  newPool() {
    this.titleModal = 'Nueva encuesta'
    this.questions = []
    this.poolFG.reset()
    this.sidenavCita.toggle(true)
  }

  async deleteQuestion(idEncuesta) {
    this.loading.show();
    await this.EncuestaService.eliminar(idEncuesta)
    this.listarEncuestas()
  }

  viewQuestion(index: number) {
    this.titleModal = 'Editar encuesta'
    const pool = this.encuestas[index]
    this.poolFG.patchValue(pool)
    this.questions = [

      { "question": pool.question1, "type": pool.type1 },
      { "question": pool.question2, "type": pool.type2 },
      { "question": pool.question3, "type": pool.type3 },
      { "question": pool.question4, "type": pool.type4 },
      { "question": pool.question5, "type": pool.type5 },
    ]
    /*for(let qu of this.questions){
      if(qu == null){
        const encontrado = this.questions.indexOf(null);
        if (encontrado !== -1) {
          this.questions.splice(encontrado, 1);
        }
      }
      
    }
    */
    this.questions = this.questions.filter(x => x.question !== null)

    // this.questions = [...pool.questions]
    this.sidenavCita.toggle(true)
  }
}
