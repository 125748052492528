import {Component, OnInit, ViewChild} from '@angular/core'
import {EncuestaService} from '@services/mysql/encuesta.service'
import {Encuesta} from '@services/model/encuesta.model'
import {FormGroup, FormControl, Validators} from '@angular/forms'
import {MatSidenav} from '@angular/material/sidenav';
import { PublicacionService } from '@services/mysql/publicacion.service';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: ['./publicaciones.component.scss']
})
export class PublicacionesComponent implements OnInit {
  @ViewChild('sidenavCita') sidenavCita: MatSidenav;
  isMobileCita = true;
  encuestas: Encuesta[] = [];
  questions: string[] = [''];

  titleModal = 'Nueva publicación'

  poolFG = new FormGroup({
    idPublicacion: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    status: new FormControl(false, Validators.required)
  });

  constructor(
    private encuestaService: EncuestaService,
    private PublicacionService: PublicacionService,
    private loading: LoadingService

    ) {
  }

  ngOnInit(): void {
    this.loading.show();
    this.listar()
  }

  async listar(){
    let data = await this.PublicacionService.listar()
    this.encuestas = data.content
    this.loading.hide();
  }

  async guardar(){
    this.loading.show();
    this.poolFG.value.status = this.poolFG.value.status == true ? 1 : 0 
    if ( this.poolFG.value.idPublicacion === null ) {
      this.poolFG.value.idPublicacion = 0
      await this.PublicacionService.crear(this.poolFG.value).then(
        () => {
          this.sidenavCita.toggle()
          this.listar()
        }
      ).catch(
        (error) => {
          console.log(error)
        }
      )
    }else{
      await this.PublicacionService.actualizar(this.poolFG.value).then(
        () => {
          this.sidenavCita.toggle()
          this.listar()
        }
      ).catch(
        (error) => {
          console.log(error)
        }
      )
    }
  }

  newPool() {
    this.titleModal = 'Nueva publicación'
    this.questions = ['']
    this.poolFG.reset()
    this.sidenavCita.toggle(true)
  }

  async deleteQuestion(idPublicacion) {
    this.loading.show();
    await this.PublicacionService.eliminar(idPublicacion)
    this.listar()
  }

  viewQuestion(index: number) {
    this.titleModal = 'Editar publicación'
    const pool = this.encuestas[index]
    this.poolFG.patchValue(pool)
    this.sidenavCita.toggle(true)
  }
}
