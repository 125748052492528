<mat-sidenav-container class="atencion-container">
  <mat-sidenav #sidenavPaciente mode="side" position="end" class="paciente-sidenav" [fixedInViewport]="isMobilePaciente"
    [fixedTopGap]="0" [fixedBottomGap]="0">
    <div (click)="sidenavPaciente.toggle()" class="close_modal d-disable-close">X</div>
    <div (click)="sidenavPaciente.toggle()" class="close_modal m-disable-close">
      <p class="arrow-left justify-content-center align-items-center d-inline-flex mb-4">
        <span class="material-icons">keyboard_arrow_left</span> Atras
      </p>
    </div>
    <div class="container nav-right-paciente">
      <div class="row avatar-row">
        <div class="col-12 col-md-12">
          <h1 class="titulo-paciente">Datos del paciente</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <form class="form-size" [formGroup]="formpersona">
            <h3 class="titulo-p-personales">Datos personales</h3>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="documento">Tipo de documento</label>
                <select id="documento" formControlName='tipoDocumento' class="form-control" disabled>
                  <option selected>Choose...</option>
                  <option value="1">DNI</option>
                  <option value="2">CE</option>
                </select>
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="numero">Número de documento</label>
                <input type="text" formControlName='numeroDocumento' class="form-control" id="numero"
                  placeholder="Password" disabled>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="nombres">Nombres</label>
                <input type="text" formControlName='nombres' class="form-control" id="nombres" placeholder="Nombres"
                  disabled>
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="apellidos">Apellidos</label>
                <input type="text" formControlName='apellidos' class="form-control" id="apellidos"
                  placeholder="Apellidos" disabled>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="tipoSexo">Fecha de nacimiento</label>
                <div class="input-group">
                  <input type="date" class="form-control" formControlName='fechaNacimiento' name="dp" disabled>

                </div>
              </div>
              <div class="form-group col-md-12 input-general">
                <label for="tipoSexo">Sexo</label>
                <select id="tipoSexo" formControlName='sexo' class="form-control" disabled>
                  <option selected>Choose...</option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </select>
              </div>
            </div>
          </form>
          <form class="form-size" [formGroup]="formuser">
            <h3 class="titulo-p-personales">Datos de contacto</h3>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="email">Correo electrónico</label>
                <input type="email" formControlName="email" class="form-control" id="email" placeholder="Correo"
                  disabled>
              </div>
            </div>
          </form>
          <form class="form-size" [formGroup]="formpaciente">
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="direccion">Dirección domiciliaria</label>
                <input type="text" class="form-control" formControlName="direccion" id="direccion"
                  placeholder="Dirección domiciliaria" disabled>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="celular">Celular</label>
                <input type="tel" formControlName="celular" class="form-control" id="celular" placeholder="Celular"
                  disabled>
              </div>
            </div>
          </form>

          <form class="form-size">
            <h3 class="titulo-p-personales">Datos de salud</h3>
            <p class="sub-titulo">Selecciona que enfermedades padeces</p>
            <div class="form-row">
              <div class="form-group col-md-12" *ngIf="activeEnfer">
                <a class="badge badge-light" *ngFor="let enfer of enfermedadesProvider"
                  [ngClass]="{ 'active': enfermedades.indexOf(enfer.description) > -1 }">
                  {{enfer.description}}
                </a>
              </div>
            </div>
          </form>
          <h3 class="titulo-p-personales">Examenes</h3>
          <div class="card-header border-0 tabla-titulo font-weight-bold">
            <div class="row card-body">
              <div class="col-4 col-md-4 col-xl-4 pr-0 pl-0">
                <p class="mr-p">Descripcion</p>
              </div>
              <div class="col-4 col-md-4 col-xl-4 pr-0 pl-0">
                <p class="mr-p">Fecha</p>
              </div>
              <div class="col-4 col-md-4 col-xl-4 pr-0 pl-0">
                <p class="mr-p">Descarga</p>
              </div>

            </div>
          </div>


          <div class="scroll-container">
            <div class="card mb-3 tabla-titulo" *ngFor="let examen of examenes">
              <div class="card-body">
                <div class="row align-items-left d-flex ">
                  <div class="col-4 col-md-4 col-xl-4 pr-0 pl-0">
                    <p> {{examen.name}}</p>
                  </div>
                  <div class="col-4 col-md-4 col-xl-4 pr-0 pl-0">
                    <p> {{examen.fecha | date: 'dd/MM/yyyy'}}</p>
                  </div>
                  <div class="col-4 col-md-4 col-xl-4 pr-0 pl-0">
                    <a [href]="examen.url" target="_blank" rel="noopener noreferrer">
                      Descargar
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-sidenav>
  <mat-sidenav-content [style.margin-right]="sidenavPaciente.opened ? 'auto' : 'auto'">
    <div class="container mt-container">
      <div class="row">
        <div class="col-12 col-md-12 col-xl-12">
          <h1 class="titulo-medico">Pacientes</h1>
        </div>
      </div>
      <div class="row header-p-t p-table">
        <div class="col-12 col-md-12 col-xl-12 ">
          <form class="form-medico">
            <div class="form-row">
              <div class="form-group col-5 col-md-4 input-general">
                <input type="text" class="form-control" id="txtapellidos" placeholder="Ingrese apellidos del paciente">
              </div>
              <div class="form-group col-5 col-md-2 input-general">
                <button class="btn btn-red" id="btnbuscar" (click)="filtarPaciene()">Buscar</button>
              </div>
              <div class="form-group col-5 col-md-4 input-general">
                <input type="text" class="form-control" id="txtnrodocumento"
                  placeholder="Ingrese nro de documento del paciente">
              </div>
              <div class="form-group col-5 col-md-2 input-general">
                <button class="btn btn-red" id="btnbuscar" (click)="filtarPacienenroDocumento()">Buscar</button>
              </div>
            </div>
          </form>
          <ng-container *ngTemplateOutlet="tabAtencion"></ng-container>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #tabAtencion style="overflow-x: auto;">
  <div class="card-header border-0 tabla-titulo font-weight-bold">
    <div class="row">
      <div class="col-3 col-md-4 col-xl-4 pr-0 pl-0">
        <p class="mr-p mb-0">Apellidos y Nombres</p>
      </div>
      <div class="col-3 col-md-3 col-xl-3 pl-0 pr-0">
        <p class="mb-0">Identidad</p>
      </div>
      <div class="col-3 col-md-2 col-xl-2 pl-0 pr-0">
        <p class="mb-0">Sexo</p>
      </div>
      <div class="col-3 col-md-3 col-xl-3 text-center  pl-0 pr-0">
      </div>
    </div>
  </div>
  <div class="scroll-container" >
    <div class="card mb-3 tabla-titulo" *ngFor="let paci of paciente">
      <div class="card-body">
        <div class="row align-items-center d-flex justify-content-center">
          <div class="col-6 col-md-4 col-xl-4 pr-0 pl-0 d-inline-flex">
            <img src="assets/img/perfil.png" class="mr-2 img-perfil" alt="perfil">
            <p>{{paci.persona.apellidos}} {{paci.persona.nombres}}</p>
          </div>
          <div class="col-6 col-md-3 col-xl-3 pl-0 pr-0">
            <p>{{paci.persona.tipodocumento.description}} {{paci.persona.numeroDocumento}}</p>
          </div>
          <div class="col-6 col-md-2 col-xl-2 pl-0 pr-0">
            <p class="c-rojo">{{paci.persona.sexo}}</p>
          </div>
          <nav></nav>
          <div class="col-6 col-md-3 col-xl-3 text-center  pl-0 pr-0">
            <button class="btn btn-outline-azul" (click)="showPaciente(paci)">Ver detalle</button>
            <button class="btn btn-outline-azul" style="background-color: darkgray;border-color: white;color: white;"
              (click)="modalExamen(paci)">Examen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>