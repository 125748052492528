import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {MaterialModule} from '../material.module';
import {NgbCarouselModule, NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {HeaderHomeComponent} from '@template/navigation/header-home/header-home.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ModalLoginComponent} from '@template/modals/modal-login/modal-login.component';
import {ModalMaestroComponent} from '@template/modals/modal-maestro/modal-maestro.component';
import {NgxGalleryModule} from 'ngx-gallery-9';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {NgxFullCalendarModule} from 'ngx-fullcalendar';
import { ModalHorarioComponent } from './modals/modal-horario/modal-horario.component';
import {MatTableModule} from '@angular/material/table';
import {ModalExamenComponent} from '@template/modals/modal-examen/modal-examen.component';

@NgModule({
  declarations:
    [
      HeaderHomeComponent,
      ModalLoginComponent,
      ModalMaestroComponent,
      ModalHorarioComponent,
      ModalExamenComponent
    ],
  imports:
    [
      BrowserModule,
      ReactiveFormsModule,
      FormsModule,
      RouterModule,
      HttpClientModule,
      MaterialModule,
      NgbCollapseModule,
      MatDialogModule,
      NgxGalleryModule,
      NgbCarouselModule,
      PdfViewerModule,
      NgxFullCalendarModule,
      MatTableModule
    ],
  exports: [
    HeaderHomeComponent
  ],
  providers: [],
  entryComponents: [
    ModalLoginComponent,
    ModalMaestroComponent,
    ModalHorarioComponent,
    ModalExamenComponent
  ]
})
export class TemplateModule {
}
