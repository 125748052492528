<h2 mat-dialog-title  class="close_modal text-right close_desktop" (click)="closeDialog()"><span>x</span></h2>
<p (click)="closeDialog()" mat-dialog-title class="arrow-left close_modal justify-content-center align-items-center d-inline-flex mb-4">
  <span class="material-icons">keyboard_arrow_left</span> Atras</p>
<mat-dialog-content class="dialog-content">
  <div class="container container-fluid">
    <div class="row">
      <div class="col-8">
        <!-- <div class="col-12 col-md-12"></div>-->
        <label>
          <input
            type="file"
            class="text-nowrap text-truncate"
            (change)="selectFile($event)"
             accept=".pdf"
          />
        </label>
      </div>
      <div class="col-4">
        <button
        class="btn btn-success btn-sm"
        [disabled]="!selectedFiles"
        (click)="upload()"
      >
        Cargar Archivo
      </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div *ngIf="currentFileUpload" class="progress mt-2">
          <div
            class="progress-bar progress-bar-info"
            role="progressbar"
            attr.aria-valuenow="{{ percentage }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: percentage + '%' }"
          >
            {{ percentage }}%
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <mat-dialog-actions class="dialog-float">
          <button class="btn btn-red"  [mat-dialog-close]="true" >Cerrar</button>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
  <br>
  <br>

</mat-dialog-content>
