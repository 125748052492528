import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MsadminService {

  constructor(
    private http: HttpClient
  ) {}
  async listar() {
    return await this.http.get<any>(apiServer + 'administrador/page/0/999').toPromise()
  }

  async crear(datos) {
    return await this.http.post<any>(apiServer + 'administrador', datos).toPromise()
  }

  async actualizar(datos) {
    return await this.http.put<any>(apiServer + 'administrador/' + datos.idAdministrador, datos).toPromise()
  }

}
