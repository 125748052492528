<div class="container mt-container">
  <div class="row">
    <div class="col-12">
      <form class="form-size" [formGroup]="formempresa">
        <h1 class="titulo-perfil">Datos de la empresa</h1>
        <div class="form-row">
          <div class="form-group col-md-6 input-general">
            <label for="razonsocial">Razón social</label>
            <input type="text" class="form-control" formControlName='razonsocial' id="razonsocial" placeholder="RazonSocial">
          </div>
          <div class="form-group col-md-6 input-general">
            <label for="ruc">RUC</label>
            <input type="text" class="form-control" formControlName='ruc' id="ruc" placeholder="RUC">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 input-general">
            <label for="direccion">Dirección</label>
            <input type="text" class="form-control" formControlName='direccion' id="direccion" placeholder="Dirección">
          </div>
          <div class="form-group col-md-6 input-general">
            <label for="telefono">Teléfono</label>
            <input type="text" class="form-control" formControlName='telefono' id="telefono" placeholder="Teléfono">
          </div>
        </div>
        <div class="form-row">
        <div class="form-group col-md-12 input-general">
          <label>Agregar Logo recomendado [ancho: 150, alto: 60] </label>
          <div class="input-group">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="inputGroupFile05" (change)="cargarimagen($event)">
              <label class="custom-file-label" for="inputGroupFile05">{{imagenSelect}}</label>
            </div>
          </div>
          <mat-card>
            <img [src]="fotoimagen" id="fotoimagen" alt="imagen">
          </mat-card>
        </div>
      </div>
        <div class="form-row mt-3 mb-5">
          <div class="form-group col-md-6">
            <button class="btn btn-lg-red" (click)="guardar()">Actualizar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
