import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSidenav } from '@angular/material/sidenav';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { ModalHorarioComponent } from '@template/modals/modal-horario/modal-horario.component';
import { Medico } from '@services/model/medico.modelo';
import { MedicoService } from '@services/core/medico.service';
import { formatDate } from '@angular/common';

import { Especialidad } from '@services/model/especialidad.modelo';
import { UserService } from '@services/user.service';
import { MsuserService } from '@services/mysql/msuser.service';
import { PersonasService } from '@services/core/personas.service';
import { MspersonaService } from '@services/mysql/mspersona.service';

import { FormGroup, FormControl, Validators, FormBuilder, ReactiveFormsModule, FormArray } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SmedicoService } from '@services/core/smedico.service';
import { EspecialidadesService } from '@services/mysql/especialidades.service';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-medico',
  templateUrl: './medico.component.html',
  styleUrls: ['./medico.component.scss']
})
export class MedicoComponent implements OnInit, OnDestroy {
  @ViewChild('sidenavMedico') sidenavMedico: MatSidenav;
  isMobileMedico = true;
  modelMedico: NgbDateStruct;
  medicos: Medico[] = [];
  medico: Medico = {};
  especialidad: string;
  especialidades: Especialidad[] = [];
  codigoEspecialidad = 0;
  formpersona: FormGroup;
  formmedico: FormGroup;
  formuser: FormGroup;
  imagenfirma: string;
  textoNombre = '';
  textodni = '';
  imagenSelectFirma: any;
  fotoimagenFirma: any;
  disableEmail = true;
  imagenSelectMedico: any;
  fotoimagenMedico: any;
  yearMin: number;
  yearMax: number;
  interval: any;
  constructor(public dialogInfo: MatDialog,
    private fb: FormBuilder,
    private userService: UserService,
    private msuserService: MsuserService,
    private personasService: PersonasService,
    private mspersonasService: MspersonaService,
    private medicoService: MedicoService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private SmedicoService: SmedicoService,
    private especialidadesService: EspecialidadesService,
    private loading: LoadingService

  ) {
    this.detectorMedico();
    const fecha = new Date();
    this.yearMax = fecha.getFullYear();
    this.yearMin = this.yearMax - environment.years;
  }

  ngOnInit(): void {
    this.loading.show();

    this.cargarEspecialidades()

    this.formuser = this.fb.group({
      uid: new FormControl(''),
      email: new FormControl('', [Validators.email, Validators.required]),
      provider: new FormControl('')
    });

    this.formpersona = this.fb.group({
      uid: new FormControl(''),
      tipoDocumento: new FormControl('', Validators.required),
      numeroDocumento: new FormControl('', [Validators.required, Validators.min(8)]),
      nombres: new FormControl('', Validators.required),
      apellidos: new FormControl('', Validators.required),
      fechaNacimiento: new FormControl('', Validators.required),
      sexo: new FormControl('', Validators.required)
    });

    this.formmedico = this.fb.group({
      uid: new FormControl(''),
      nombre: new FormControl(''),
      texto: new FormControl('', Validators.required),
      nroColegio: new FormControl('', Validators.required),
      rne: new FormControl('', Validators.required),
      especialidad: new FormControl(''),
      precio: new FormControl('', Validators.required),
      celular: new FormControl('', Validators.required),
      direccion: new FormControl('', Validators.required),
      firma: new FormControl(''),
      imagen: new FormControl(''),
      estado: new FormControl('')
    });

    this.cargarMedicos()
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  async cargarEspecialidades() {
    let data = await this.especialidadesService.listar()
    this.especialidades = data

  }

  async cargarMedicos() {
    let data = await this.SmedicoService.listar()
    this.medicos = data.content
    this.loading.hide();
  }

  async onChangeEspecialidad() {
    this.loading.show();
    let data;
    if (this.codigoEspecialidad == 0) {
      data = await this.SmedicoService.listar()
    } else {
      data = await this.SmedicoService.listarxespecialidad(this.codigoEspecialidad)
    }
    this.loading.hide();
    this.medicos = data.content
  }

  async onChangeNonbreMedico(event) {
    if (event.target.value.length >= 4) {
      await this.onChangeEspecialidad()
      let nombremedico = event.target.value;
      let data = await this.SmedicoService.listarxnombre(nombremedico)
      this.medicos = data
    } else if (event.target.value.length == 0) {
      let data = await this.SmedicoService.listar()
      this.medicos = data.content
    }
  }

  async onChangeDniMedico(event) {
    if (event.target.value.length >= 4) {
      await this.onChangeEspecialidad()
      let dnimedico = event.target.value;
      let data = await this.SmedicoService.listarxndocumento(dnimedico)
      this.medicos = data

    } else if (event.target.value.length == 0) {
      let data = await this.SmedicoService.listar()
      this.medicos = data.content
    }
  }

  cargarimagen(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.fotoimagenFirma = reader.result;
    };
    if (event.target.files) {
      reader.readAsDataURL(event.target.files[0]);
      this.imagenSelectFirma = event.target.files[0].name;
    }
  }

  cargarimagenMedico(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.fotoimagenMedico = reader.result;
    };
    if (event.target.files) {
      reader.readAsDataURL(event.target.files[0]);
      this.imagenSelectMedico = event.target.files[0].name;
    }
  }

  idEspecialidad

  badgeSelection(espe) {
    this.especialidad = espe.description
    this.idEspecialidad = espe.idEspecialidad
  }

  showMedico(medico) {
    this.formmedico.reset();
    this.formpersona.reset();
    this.formuser.reset();
    this.fotoimagenMedico = null;
    this.imagenSelectMedico = '';
    this.fotoimagenFirma = null;
    this.imagenSelectFirma = '';
    this.medico = medico;

    this.formmedico.patchValue({
      uid: medico.uid,
      precio: medico.precio,
      nombre: medico.nombre,
      celular: medico.celular,
      texto: medico.texto,
      nroColegio: medico.nroColegio,
      rne: medico.rne,
      especialidad: medico.especialidad,
      direccion: medico.direccion,
      firma: medico.firma,
      imagen: medico.imagen,
      estado: medico.estado
    });
    this.fotoimagenFirma = medico.firma;
    this.especialidad = medico.especialidad?.description;
    this.idEspecialidad = medico.especialidad?.idEspecialidad;
    this.fotoimagenMedico = medico.imagen;

    if (this.medico.uid) {
      this.disableEmail = true;
      this.userService.getById(this.medico.uid).valueChanges().subscribe(
        (userdata: any) => {
          if (userdata) {
            this.formuser.patchValue({
              uid: userdata.uid,
              email: userdata.email,
              provider: userdata.provider
            });
          }
        }
      );
      this.personasService.getById(this.medico.uid).valueChanges().subscribe(
        (personadata: any) => {
          if (personadata) {
            this.formpersona.patchValue({
              uid: personadata.uid,
              nombres: personadata.nombres,
              apellidos: personadata.apellidos,
              tipoDocumento: personadata.tipoDocumento,
              numeroDocumento: personadata.numeroDocumento,
              sexo: personadata.sexo,
              fechaNacimiento: personadata.fechaNacimiento
            });
          }
        }
      );
    } else {
      this.disableEmail = false;
      this.formuser.patchValue({
        uid: '',
        email: '',
        provider: ''
      });
      this.formpersona.patchValue({
        uid: '',
        nombres: '',
        apellidos: '',
        tipoDocumento: '',
        numeroDocumento: '',
        sexo: '',
        fechaNacimiento: ''
      });
    }

    this.sidenavMedico.toggle();
  }
  detectorMedico() {
    this.isMobileMedico = this.deviceService.isMobile();
    this.isMobileMedico === true ? this.isMobileMedico = false : this.isMobileMedico = true;
  }

  modalCalendario() {
    if (this.medico.idMedico) {
      this.dialogInfo.open(ModalHorarioComponent, {
        width: '1400px',
        height: '900px',
        data: {
          idMedico: this.medico.idMedico,
          uid: this.medico.uid

        }
      });
    }
  }

  validar() {
    if (this.formuser.valid && this.formpersona.valid && this.formmedico.valid) {
      this.guardarDatosMedicos();
    }
  }

  upperCase(dato: string) {
    return dato.toUpperCase();
  }

  formatPersona(formpersona: any) {
    formpersona.value.nombres = this.upperCase(formpersona.value.nombres);
    formpersona.value.apellidos = this.upperCase(formpersona.value.apellidos);
  }

  guardarDatosMedicos() {
    this.loading.show();
    this.formatPersona(this.formpersona);
    if (this.formuser.valid) {
      this.formmedico.value.nombre = this.formpersona.value.nombres + ' ' + this.formpersona.value.apellidos;
      if (this.fotoimagenMedico) { this.formmedico.value.imagen = this.fotoimagenMedico; }
      if (this.fotoimagenFirma) { this.formmedico.value.firma = this.fotoimagenFirma; }
      if (this.formmedico.value.estado === undefined) { this.formmedico.value.estado = false; }
      if (this.especialidad) { this.formmedico.value.especialidad = this.especialidad; }
      if (this.idEspecialidad) { this.formmedico.value.idEspecialidad = this.idEspecialidad; }
      if (this.formmedico.value.imagen === undefined) { this.formmedico.value.imagen = ''; }
      if (this.formmedico.value.firma === undefined) { this.formmedico.value.firma = ''; }
      if (this.formmedico.value.especialidad === undefined) { this.formmedico.value.especialidad = ''; }
      if (this.formmedico.value.uid) {
        this.updateMedico()
      } else {
        this.crearMedico()
      }
    }
  }

  async crearMedico() {

    /*this.medicoService.registra(this.formmedico.value, this.formpersona.value, this.formuser.value).then(
      async (data: any) => {
        setTimeout(() => {
          this.cargarMedicos().then(
            async (data: any) => {
              this.sidenavMedico.toggle()
            }
          );
        }, 3000);
      }
    );*/
    await this.medicoService.registra(this.formmedico.value, this.formpersona.value, this.formuser.value);
    this.interval = setInterval(async () => {
      await this.cargarMedicos();
    }, 5000);
    this.sidenavMedico.toggle()

  }

  updateMedico() {

    let datospersona = {
      "apellidos": this.formpersona.value.apellidos,
      "fechaNacimiento": this.formpersona.value.fechaNacimiento,
      "idPersona": this.medico.id_persona,
      "nombres": this.formpersona.value.nombres,
      "numeroDocumento": this.formpersona.value.numeroDocumento,
      "sexo": this.formpersona.value.sexo,
      "id_tipo_documento": this.formpersona.value.tipoDocumento
    }

    let datosmedico = {
      "celular": this.formmedico.value.celular,
      "direccion": this.formmedico.value.direccion,
      "email": this.formuser.value.email,
      "estado": this.formmedico.value.estado == true ? 1 : 0,
      "firma": this.formmedico.value.firma,
      "idMedico": this.medico.idMedico,
      "id_especialidad": this.formmedico.value.idEspecialidad,
      "id_persona": this.medico.id_persona,
      "imagen": this.formmedico.value.imagen,
      "nombre": this.formmedico.value.nombre,
      "nroColegio": this.formmedico.value.nroColegio,
      "precio": this.formmedico.value.precio,
      "rne": this.formmedico.value.rne,
      "texto": this.formmedico.value.texto,
      "uid": this.formmedico.value.uid
    }
    this.msuserService.listarxusername(this.formuser.value.email).then(
      (userdata: any) => {
        if (userdata) {
          let datosuser = {
            "email": this.formuser.value.email,
            "id": userdata.id,
            "name": this.formmedico.value.nombre,
            "roles": [{
              id: 2,
              name: "MEDICO"
            }],
            "uid": this.formmedico.value.uid,
            "username": this.formuser.value.email
          }
          this.medicoService.update(this.formmedico.value, datosmedico, this.formuser.value, datosuser).then(
            async (data: any) => {
              await this.personasService.update(this.formpersona.value)
              await this.mspersonasService.actualizar(datospersona)
              await this.cargarMedicos().then(
                (data: any) => {
                  this.sidenavMedico.toggle()
                }
              );
            }
          );

        }
      }
    );

  }

}

