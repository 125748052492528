import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {

  constructor(
    private http: HttpClient
  ) { }

  async listar() {
    return await this.http.get<any>(apiServer + 'encuesta/page/0/999').toPromise()
  }
  async listarencuestaatencion() {
    return await this.http.get<any>(apiServer + 'encuestaatencion/page/0/99999').toPromise()
  }
  async listarencuestaatencionxfecha(fechaini,fechafin) {
    return await this.http.get<any>(apiServer + 'encuestaatencion/reporteencuestasatencion/'+fechaini+'/'+fechafin+'/0/99999').toPromise()
  }

  async crear(datos) {
    return await this.http.post<any>(apiServer + 'encuesta', datos).toPromise()
  }

  async actualizar(datos) {
    return await this.http.put<any>(apiServer + 'encuesta/' + datos.idEncuesta, datos).toPromise()
  }

  async eliminar(idEncuesta) {
    return await this.http.delete<any>(apiServer + 'encuesta/' + idEncuesta).toPromise()
  }

}
