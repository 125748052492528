import { Component, OnInit, ViewChild } from '@angular/core';
import { EncuestaService } from '@services/mysql/encuesta.service';
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { FormGroup, FormControl, Validators, FormBuilder, ReactiveFormsModule, FormArray } from '@angular/forms';
import { ExporterService } from '@services/exporter.service'
import { DateAdapter } from '@angular/material/core'
import { map, startWith } from 'rxjs/operators'
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-encuestaatencion',
  templateUrl: './encuestaatencion.component.html',
  styleUrls: ['./encuestaatencion.component.scss']
})
export class EncuestaatencionComponent implements OnInit {
  encuestasatencion: any[] = [];
  displayedColumns: string[] = [
    'fecha', 'paciente', 'doctor', 'detalles'
  ]
  isMobileMedico = true

  dataSource = new MatTableDataSource<any>()
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  })
  formencuesta: FormGroup;

  encuestaselect: any = null
  @ViewChild('sidenavPago') sidenavPago

  codigoMedico = ''
  constructor(
    private fb: FormBuilder,
    private EncuestaService: EncuestaService,
    private exporterService: ExporterService,
    private dadapter: DateAdapter<any>,
    private loading: LoadingService

  ) { 
    this.dadapter.setLocale('Es')

  }

  ngOnInit(): void {
    this.loading.show();
    this.formencuesta = this.fb.group({
      preguntas: new FormControl('')
    });
    this.EncuestaService.listarencuestaatencion().then(
      (data: any) => {

        this.encuestasatencion = data.content;
        this.dataSource = new MatTableDataSource<any>(this.encuestasatencion)
        this.dataSource.paginator = this.paginator
        this.loading.hide();
      }

    );
  }

  exportExcel() {
    /*let dataexport: any[]=[
      {
      Fecha:String,
      Hora:String,
      Medico:String,
      Celular:String,
      Especialidad:String,
      Paciente:String,
      CelularP:String,
      Estado:String,
      Metodo:String,
      Precio:String,
    }
    ];*/

    let ELEMENT_DATA: Payment[] = []

    interface Payment {
      paciente: String;
      doctor: String;
      preguntas: String;
      fecha: String;

    }
    for (let item of this.dataSource.data) {
      //this.dataSource.data.forEach(item => {
      let validadoi;
      let customObj = new PeriodicElement();
      customObj.paciente = item.atencion.paciente.persona.nombres;
      customObj.doctor = item.atencion.medico.nombre;
      customObj.preguntas = item.respuesta1+'\n'+item.respuesta2+'\n'+item.respuesta3+'\n'+item.respuesta4+'\n'+item.respuesta5
      customObj.fecha = item.fecha

      ELEMENT_DATA.push(customObj);

    }
    //);

    this.exporterService.exposrtToExcel(ELEMENT_DATA, this.codigoMedico)
  }

  seleccionarEncuesta(element: any) {
    this.encuestaselect = element
    this.sidenavPago.toggle()
    if(element.respuesta1 == "null \n "){
      element.respuesta1= ""
    }
    if(element.respuesta2 == "null \n "){
      element.respuesta2= ""
    }
    if(element.respuesta3 == "null \n "){
      element.respuesta3= ""
    }
    if(element.respuesta4 == "null \n "){
      element.respuesta4= ""
    }
    if(element.respuesta5 == "null \n "){
      element.respuesta5= ""
    }

    this.formencuesta.patchValue({
      preguntas: element.respuesta1+'\n'+element.respuesta2+'\n'+element.respuesta3+'\n'+element.respuesta4+'\n'+element.respuesta5,
    });

  }
  onSelectionChange() {
    if (this.range.value.end && this.range.value.start) {
      this.getAllEncuestaAtenciones(this.range.value.start, this.range.value.end)
    }
  }
  getAllEncuestaAtenciones(fechaIni, fechaFin) {
    this.loading.show();
    this.EncuestaService.listarencuestaatencionxfecha(fechaIni,fechaFin).then(
      (data: any) => {
        this.encuestasatencion = data.content;
        this.dataSource = new MatTableDataSource<any>(this.encuestasatencion)
        this.dataSource.paginator = this.paginator
        this.loading.hide();
      }
    )
    /*let result = []
    this.atencionesService.getReporte01(fechaIni.toISOString().split('T')[0], fechaFin.toISOString().split('T')[0], uid).valueChanges().subscribe(
      (data: any) => {
        console.info(data)
        data.forEach(element => {
          console.info(element.medicoUid)
          const telefono = this.buscarNombremedico(element.medicoUid)
          const telefonoPaciente = this.buscarNumeroPaciente(element.pacienteUid)
          result.push({
            medicoUid: element.medicoUid,
            fecha: element.fecha,
            hora: element.hora,
            medico: element.nombreMedico,
            especialidad: element.especialidad,
            celular: telefono,
            celularPaciente: telefonoPaciente,
            paciente: element.paciente,
            estado: element.estado
          })
        })
        // result = this.filtrarporMedico(result, uid);
        this.convertJson(result)
      }
    )*/
  }

}
export class PeriodicElement {
  paciente: String;
  doctor: String;
  preguntas: String;
  fecha: String;
}