export class FileUpload {
  key: string;
  name: string;
  url: string;
  pacienteUid: string;
  fecha?:string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}
