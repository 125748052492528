<div class="container">
  <div class="row">
    <div class="col-12 col-md-12 col-xl-12">
      <h1 class="titulo-perfil">Reporte de atenciones</h1>
    </div>
    <!--<div class="col-12 col-md-12 col-xl-12">-->
    <div class="col-12 col-md-12 col-xl-12">
          <mat-form-field appearance="fill" [formGroup]="range">
            <mat-label>Fecha inicial</mat-label>
            <input matInput [matDatepicker]="dpini" formControlName="start" id="inifecha">
            <mat-datepicker-toggle matSuffix [for]="dpini"></mat-datepicker-toggle>
            <mat-datepicker #dpini></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" [formGroup]="range">
            <mat-label>Fecha final</mat-label>
            <input matInput [matDatepicker]="dpfin" formControlName="end">
            <mat-datepicker-toggle matSuffix [for]="dpfin"></mat-datepicker-toggle>
            <mat-datepicker #dpfin></mat-datepicker>
          </mat-form-field>
        </div>
    <div class="container">
      <div class="row">
        <div class="col-8 col-md-8 col-xl-8">
          <mat-form-field class="example-full-width">
            <input type="text" placeholder="Buscar Médico" aria-label="Number" matInput [formControl]="myControl"
              [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre"
                (onSelectionChange)="onSelectionChange($event, option.uid)">
                {{option.nombre}} <strong>{{option.especialidad.description}}</strong>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row scroll-container" style="overflow-x: auto;" >
    <div class="col-12">
      <table mat-table [dataSource]="dataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="fecha">
          <th mat-header-cell *matHeaderCellDef> Fecha </th>
          <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="hora">
          <th mat-header-cell *matHeaderCellDef> Hora </th>
          <td mat-cell *matCellDef="let element"> {{element.hora}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="paciente">
          <th mat-header-cell *matHeaderCellDef> Paciente </th>
          <td mat-cell *matCellDef="let element"> {{element.paciente.persona.nombres}} {{element.paciente.persona.apellidos}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="costo">
          <th mat-header-cell *matHeaderCellDef> Costo </th>
          <td mat-cell *matCellDef="let element"> {{element.precio}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
    </div>
    <div>
      <br>
      <button class="btn btn-outline-azul" (click)="exportExcel()">Export Excel</button>
    </div>
  </div>
</div>