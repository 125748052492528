import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnfermedadesService {

  constructor(
    private http: HttpClient
  ) { }

  async listar(){
    return await this.http.get<any>(apiServer + 'enfermedad/page/0/999').toPromise()
  }

  async crear(datos){
    return await this.http.post<any>(apiServer + 'enfermedad', datos).toPromise()
  }

  async actualizar(datos){
    return await this.http.put<any>(apiServer + 'enfermedad/' + datos.idEnfermedad, datos).toPromise()
  }

}
