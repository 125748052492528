<div class="container mt-container">
  <div class="row">
    <div class="col-12">
      <form class="form-size" [formGroup]="formpago">
        <h1 class="titulo-perfil">Configuración de pago</h1>
        <div class="form-row">
          <div class="form-group col-md-6 input-general">
            <label for="provider">Proveedor</label>
            <input type="text" formControlName='provider' class="form-control" id="provider" placeholder="Proveedor">
          </div>
          <div class="form-group col-md-6 input-general">
            <label for="apiServer">url-api</label>
            <input type="text" formControlName='apiServer' class="form-control" id="apiServer" placeholder="Api">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 input-general">
            <label for="APIKEY">API-KEY</label>
            <input type="text" formControlName='APIKEY' class="form-control" id="APIKEY" placeholder="API-KEY">
          </div>
          <div class="form-group col-md-6 input-general">
            <label for="APISK">API-SK</label>
            <input type="text" formControlName='APISK' class="form-control" id="APISK" placeholder="API-SK">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 input-general">
            <label for="comercio">Comercio</label>
            <input type="text" formControlName='comercio' class="form-control" id="comercio" placeholder="comercio">
          </div>
          <div class="form-group col-md-6 input-general">
            <label for="moneda">moneda</label>
            <input type="text" formControlName='moneda' class="form-control" id="moneda" placeholder="moneda">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 input-general">
            <label for="estado">Estado</label>
            <select id="estado" formControlName='estado' class="form-control">
              <option value="activo">activo</option>
              <option value="inactivo">inactivo</option>
            </select>
          </div>
        </div>
        <div class="form-row mt-3 mb-5">
          <div class="form-group col-md-6">
            <button class="btn btn-lg-red" (click)="guardar()">Actualizar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
