<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-12 col-lg-5 col-xl-5 centrar-div">
      <div class="card">
        <div class="logo-login">
          <img src="assets/img/logo.svg" class="img-fluid img-login" alt="logo"/>
          <h3 class="titulo-login">Inicia Sesión para ingresar</h3>
        </div>
        <form>
          <div class="form-group input-general">
            <label>Correo electrónico</label>
            <input type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Email">
          </div>
          <div class="form-group input-general">
            <label>Contraseña</label>
            <input type="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="****  ">
          </div>
          <div class="form-group forget-password">
            <p class="input-general">¿Olvidaste tu contraseña? <a href="#" (click)="recuperarClave()"><span class="span-contrasena" >Haz click aquí</span></a></p>
          </div>
          <div class="form-group">
            <button (click)="iniciarSesion()" *ngIf="operation == 'login'"  class="btn btn-lg-red mt-4">Iniciar sesión</button>
          </div>
        </form>
      </div>
    </div>

    <div class="col-12 col-md-7 col-lg-7 col-xl-7 bg-login centrar-div">
      <ngb-carousel class="ngb-carousel">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/img/section1.png" alt="Angular Carousel 1">
          </div>
          <div class="carousel-caption">
            <h3>Para vencer una enfermedad el primer medicamento es la actitud positiva</h3>
            <p>¡Que te mejores pronto!</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/img/section2.png" alt="Angular Carousel 2">
          </div>
          <div class="carousel-caption">
            <h3>La salud no sólo es la ausencia de enfermedad.</h3>
            <p>Mil enfermos requieren mil curas…</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="assets/img/section3.png" alt="Angular Carousel 3">
          </div>
          <div class="carousel-caption">
            <h3>La salud no sólo es la ausencia de enfermedad.</h3>
            <p>Mil enfermos requieren mil curas…</p>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

  </div>
</div>

