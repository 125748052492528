import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ConfigurationService } from '@services/core/configuration.service';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss']
})
export class PagoComponent implements OnInit {
  formpago: FormGroup;
  constructor(
    private configurationService: ConfigurationService,
    private loading: LoadingService

  ) {
    this.formpago = new FormGroup({
      provider: new FormControl(''),
      apiServer: new FormControl(''),
      APIKEY: new FormControl(''),
      APISK: new FormControl(''),
      comercio: new FormControl(''),
      moneda: new FormControl(''),
      estado: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.loading.show();
    this.configurationService.getPago().valueChanges().subscribe(
      (data: any) => {
        this.loading.hide();
        if ( data ) {
          this.formpago.patchValue({
            provider: data.provider,
            apiServer: data.apiServer,
            APIKEY: data.APIKEY,
            APISK: data.APISK,
            comercio: data.comercio,
            moneda: data.moneda,
            estado: data.estado
          });
        }
      }
    );
  }
  guardar(){
    this.loading.show();
    this.configurationService.setPago(this.formpago.value).then(
      () => {
        this.loading.hide();
        alert('registrado exitosamente');
      }
    ).catch(
      (error) => {
        this.loading.hide();
        console.log(error);
      }
    );
  }
}
