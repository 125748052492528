import {Component, OnInit, ViewChild} from '@angular/core'
import {MatSidenav} from '@angular/material/sidenav'
import {Router} from '@angular/router'
import {DeviceDetectorService} from 'ngx-device-detector'
import {TipoAtencionService} from '@services/core/tipoatencion.service'
import {TipoAtencion} from '@services/model/tipoatencion.modelo'
import {FormGroup, FormControl} from '@angular/forms'
import { CitaService } from '@services/mysql/cita.service'
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.scss']
})
export class CitaComponent implements OnInit {
  @ViewChild('sidenavCita') sidenavCita: MatSidenav
  isMobileCita = true
  tipoatenciones: TipoAtencion[] = []
  form: FormGroup

  constructor(
    private tipoAtencionService: TipoAtencionService,
    private router: Router, private deviceService: DeviceDetectorService,
    private CitaService: CitaService,
    private loading: LoadingService
    ) {
    this.detectorCita()
    // this.tipoAtencionService.getAll().valueChanges().subscribe(
    //   (data: any) => {
    //     this.tipoatenciones = data || []
    //   }
    // )

    this.form = new FormGroup({
      idTipoAtencion: new FormControl(''),
      title: new FormControl(''),
      description: new FormControl(''),
      status: new FormControl('')
    })
  }

  ngOnInit(): void {
    this.loading.show();
    this.cargarDatos()
  }

  async cargarDatos(){
    let data = await this.CitaService.listar()
    this.tipoatenciones = data.content
    this.loading.hide();
  }

  showCita(item) {
    this.form.patchValue({
      idTipoAtencion: item.idTipoAtencion,
      title: item.title,
      description: item.description,
      status: item.status
    })
    this.sidenavCita.toggle()
  }

  detectorCita() {
    const isdeviceMobile = this.deviceService.isMobile()
    isdeviceMobile === true ? this.isMobileCita = false : this.isMobileCita = true
  }

  async guardar() {
    this.loading.show();
    if ( this.form.value.description ) {
      this.form.value.status = this.form.value.status == true ? 1 : 0
      if ( this.form.value.idTipoAtencion === undefined ) {
        this.form.value.idTipoAtencion = 0
        await this.CitaService.crear(this.form.value).then(
          () => {
            this.sidenavCita.toggle()
          }
        ).catch(
          (error) => {
            console.log(error)
          }
        )
      }else{
        await this.CitaService.actualizar(this.form.value).then(
          () => {
            this.sidenavCita.toggle()
          }
        ).catch(
          (error) => {
            console.log(error)
          }
        )
      }
    }
    this.cargarDatos()
  }

  async delete(idTipoAtencion) {
    if (confirm("¿Está seguro de eliminar?")) {
      this.loading.show();
      await this.CitaService.eliminar(idTipoAtencion).then(
        () => {
          this.cargarDatos()
        }
      ).catch(
        (error) => {
          console.log(error)
        }
      )
      alert("Eliminado");
    } else {
      alert("Cancelado");
    }
  }
}
