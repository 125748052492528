import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { UserService } from '@services/user.service';
import { Router } from '@angular/router';
import { User } from '@shared/interfaces/user';
import { AdminService } from '@services/core/admin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  operation = 'login';
  email = null;
  password = null;
  nick = null;
  constructor(
      private authenticationService: AuthenticationService,
      private adminService: AdminService,
      private router: Router
      ) { }

  ngOnInit() {
  }

  iniciarSesion(){
    if ( this.email === 'root' ) {
      if ( this.authenticationService.getAuthRoot(this.password) ) {
        this.router.navigate(['/sitio/admin']);
      }
    }else{
      debugger;
      this.authenticationService.loginWithEmail(this.email, this.password).then(
        (data) => {
          this.authAdmin(data.user.uid);
        }
      ).catch((error) => {
        alert('logeado error');
        console.log(error);
      });
    }
  }

  authAdmin(uid){
    this.adminService.getById(uid).valueChanges().subscribe(
      (data: any) => {
        if (data.estado === 'activo') {
          this.router.navigate(['/sitio/medico']);
        }
      },
      (error) => {
        alert('logeado error');
      }
    );
  }

  recuperarClave(){
    if ( this.email ) {
      this.authenticationService.recoveryPassword(this.email);
    }else{
      alert('Ingrese su correo electrónico');

    }
  }

}
