<h2 mat-dialog-title  class="close_modal text-right" (click)="closeDialog()"><span>x</span></h2>
<mat-dialog-content class="text-center">
  <div class="container">
    <div class="row text-center">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12">
        <h1 class="mt-3 titulo-modal">Cerrar sesión</h1>
        <p class="mt-2 mb-4 texto-modal">Espero haberte ayudado. ¿Estás seguro que quieres cerrar sesión?</p>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="btn btn-ligt text-black-50 mr-3"  [mat-dialog-close]="true" >Cancelar</button>
  <button class="btn btn-red text-black" (click)="closeSession()" [mat-dialog-close]="true" >Cerrar sesión</button>
</mat-dialog-actions>

