import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable, LOCALE_ID } from '@angular/core';
import es from '@angular/common/locales/es';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { CommonModule } from '@angular/common';


import * as Sentry from '@sentry/browser';


import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TemplateModule} from '@template/template.module';
import {PagesModule} from '@pages/pages.module';
import {SecurityModule} from '@security/security.module';

import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';

Sentry.init({
  dsn: 'https://6fa0e2c9182941a2a3b2f508bc419e2b@o401499.ingest.sentry.io/5261181'
});

registerLocaleData(es);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.log(error);
    const eventId = Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    AngularFireDatabaseModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    PagesModule,
    SecurityModule,
    TemplateModule,
    NgxFullCalendarModule,
    DeviceDetectorModule.forRoot(),
    NgxGalleryModule,
    PdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: LOCALE_ID, useValue: 'es-*' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
