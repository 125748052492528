import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Atencion } from '../model/atenciones.modelo';
import { Informe } from '@services/model/informes.modelo';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AtencionesService {

  constructor(private angularFireDatabase: AngularFireDatabase) { }

  setcie(cie: any){
    return this.angularFireDatabase.object('/cie10').set(cie);
   }
   getAllPacienteExamenes(pacienteUid: any){
    return this.angularFireDatabase.list('/examenes', ref => ref.orderByChild('pacienteUid').equalTo(pacienteUid));
  }
  getFecha(medicoUid: any, fecha: any){
    return this.angularFireDatabase.list('/medicos/' + medicoUid + '/fechas/' + fecha);
   }
  getAllMedico(medicoUid: any){
   return this.angularFireDatabase.list('/atenciones', ref => ref.orderByChild('medicoUid').equalTo(medicoUid));
  }
  getReporte01(fechaini: any, fechafin: any, medicoUid: any){
    return this.angularFireDatabase.list('/atenciones', ref => ref.orderByChild('fecha').startAt(fechaini).endAt(fechafin));
  }

  getAllPaciente(pacienteUid: any){
    return this.angularFireDatabase.list('/atenciones', ref => ref.orderByChild('pacienteUid').equalTo(pacienteUid));
  }
  getById(uid: string){
   return this.angularFireDatabase.object('/atenciones/' + uid);
  }
  create(atencion: Atencion){
    return this.angularFireDatabase.object('/atenciones/' + atencion.uid).set(atencion);
  }
  update(atencion: Atencion){
    return this.angularFireDatabase.object('/atenciones/' + atencion.uid).set(atencion);
  }
  addLinkVideoLlamada(uid: string, Link: any){
    return this.angularFireDatabase.object('/atenciones/' + uid + '/linkVideoLlamada').set(Link);
  }
  changePayment(uid: string, payment: any){
    return this.angularFireDatabase.object('/atenciones/' + uid + '/payment').set(payment);
  }
  changeStatus(uid: string, estado: any){
    return this.angularFireDatabase.object('/atenciones/' + uid + '/estado').set(estado);
  }
  setInforme(uid: string, informe: Informe){
    return this.angularFireDatabase.object('/atenciones/' + uid + '/informe').set(informe);
  }
}
