import {NgModule} from '@angular/core';
import {LoginComponent} from "@security/login/login.component";
import {NgbCarouselModule, NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TemplateModule} from "@template/template.module";

@NgModule({
  declarations:
    [
      LoginComponent
    ],
  imports:
    [
      NgbCarouselModule,
      CommonModule,
      FormsModule,
      NgbDatepickerModule,
      TemplateModule
    ],
  exports: [
    LoginComponent
  ],
  providers: [TemplateModule],
  entryComponents: []
})
export class SecurityModule {


}
