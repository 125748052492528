import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ExporterService } from '@services/exporter.service';
import { AtencionesService } from '@services/core/atenciones.service';
import { MedicoService } from '@services/core/medico.service';
import { Medico } from '@services/model/medico.modelo';
import { Atencion } from '@services/model/atenciones.modelo';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Especialidad } from '@services/model/especialidad.modelo';
import { EspecialidadesService } from '@services/mysql/especialidades.service';
import { EspecialidadesServicef } from '@services/core/especialidades.service';
import { SmedicoService } from '@services/core/smedico.service'
import { MspacienteService } from '@services/mysql/mspaciente.service'
import { MsatencionService } from '@services/mysql/msatencion.service'
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import {MatChipInputEvent} from '@angular/material/chips';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-rptmedicos',
  templateUrl: './rptmedicos.component.html',
  styleUrls: ['./rptmedicos.component.scss']
})
export class RptMedicosComponent implements OnInit, AfterViewInit {
  codigoEspecialidad = '';
  codigoMedico = '';
  especialidades: Especialidad[] = [];

  displayedColumns: string[] = ['fecha', 'hora', 'paciente', 'estado', 'costo'];
  dataSource = new MatTableDataSource<any>()

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  uid: string;
  medicos: Medico[] = [];
  atenciones: Atencion[] = [];
  filteredOptions: Observable<Medico[]>;
  myControl = new FormControl();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  constructor(
    private exporterService: ExporterService,
    private atencionesService: AtencionesService,
    private medicoService: MedicoService,
    private especialidadesService: EspecialidadesService,
    private EspecialidadesServicef: EspecialidadesServicef,
    private smedicoService: SmedicoService,
    private mspacienteService: MspacienteService,
    private msatencionService: MsatencionService,
    private dadapter: DateAdapter<any>,
    private router: Router,
    private loading: LoadingService

  ) {
    this.dadapter.setLocale('Es');
    /*this.EspecialidadesServicef.getAll().valueChanges().subscribe(
      (data: any) => {
        this.especialidades = data;
      }
    );*/
    this.especialidadesService.listarEspecialidades().then(
      (data: any) => {
        this.especialidades = data;
      }
    );
    /*this.medicoService.getAll().valueChanges().subscribe(
      (data: any) => {
        this.medicos = data;
      }
    );*/
    this.smedicoService.listar().then(
      (data: any) => {
        this.medicos = data.content;
      }
    );
    this.msatencionService.listar().then(
      (data: any) => {
        this.atenciones = data.content;
        this.dataSource = new MatTableDataSource<Atencion>(this.atenciones);
        this.dataSource.paginator = this.paginator;
        this.loading.hide();
      }
    )
  }

  ngOnInit(): void {
    this.loading.show();
    this.dataSource.paginator = this.paginator;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      const mainHeader = document.getElementById('inifecha');
      if (mainHeader) {
        mainHeader.focus();
      }
    });
  }
  ngAfterViewInit() {

    //document.getElementById('inifecha').focus();

  }

  private _filter(value: string): Medico[] {
    const filterValue = value.toLowerCase();
    return this.medicos.filter(option => option.nombre?.toLowerCase().indexOf(filterValue) === 0);
  }

  onSelectionChange(event, uid){
    if ( event.isUserInput ) {
      this.getAllAtenciones(uid,this.range.value.start, this.range.value.end);
    }
  }
  exportExcel(){
    let ELEMENT_DATA: Payment[] = []

    interface Payment {
      Fecha:String;
      Hora:String;
      Medico:String;
      Paciente:String;
      Estado:String;
      Costo:String;

    }

    for (let item of this.dataSource.data){
      //this.dataSource.data.forEach(item => {

        let customObj = new PeriodicElement();
        customObj.Fecha=item.fecha;
        customObj.Hora=item.hora;
        customObj.Medico=item.medico.nombre
        customObj.Paciente=item.paciente.persona.nombres+' '+item.paciente.persona.apellidos
        customObj.Estado=item.estado
        customObj.Costo=item.precio
        ELEMENT_DATA.push(customObj);

    }
    //);

    this.exporterService.exposrtToExcel(ELEMENT_DATA, this.codigoMedico)  
  }

  getAllAtenciones(uid,fechaIni, fechaFin){
    this.loading.show();
    this.msatencionService.listarxuidmedico(uid).then(
      (data: any) => {
        this.atenciones = this.filtrarporFecha(data.content, fechaIni, fechaFin);
        this.dataSource = new MatTableDataSource<Atencion>(this.atenciones);
        this.dataSource.paginator = this.paginator;
        this.loading.hide();
      }
    )
    /*this.msatencionService.listarfechainifin(fechaIni,fechaFin).then(
      (data: any) => {
        console.log(data.content)
        this.atenciones = data.content;
        this.dataSource = new MatTableDataSource<Atencion>(this.atenciones)
        console.log("datasource", this.dataSource)
        this.dataSource.paginator = this.paginator
      }
    )*/
  }
  filtrarporFecha(atens: Atencion[], fechaIni: Date, fechaFin: Date){

    const fechaIniGMT = new Date(Date.UTC(fechaIni.getFullYear(), fechaIni.getMonth(), fechaIni.getDate()));
    const fechaFinGMT = new Date(Date.UTC(fechaFin.getFullYear(), fechaFin.getMonth(), fechaFin.getDate()));
    return atens.filter(x =>
          new Date((new Date(x.fecha)).toUTCString()) >= fechaIniGMT &&
          new Date((new Date(x.fecha)).toUTCString()) <= fechaFinGMT
    );
  }
  convertJson(data: Atencion[]) {
   /* ELEMENT_DATA = [];
    data.forEach(element => {
      ELEMENT_DATA.push({
        fecha: element.fecha,
        hora: element.hora,
        paciente: element.paciente,
        estado: element.estado,
        costo: element.precio
      });
    });*/
    //this.dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    //this.dataSource.paginator = this.paginator;
  }

}
export class PeriodicElement {
  Fecha:String;
  Hora:String;
  Medico:String;
  Paciente:String;
  Estado:String;
  Costo:String;
}
/*
export interface PeriodicElement {
  fecha: string;
  hora: string;
  paciente: string;
  estado: string;
  costo: number;
}

let ELEMENT_DATA: PeriodicElement[] = [];*/
