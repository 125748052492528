import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Especialidad } from '@services/model/especialidad.modelo';

@Injectable({
  providedIn: 'root'
})
export class EspecialidadesServicef {
  specialty = '/specialty';
  constructor(private angularFireDatabase: AngularFireDatabase) { }

  getAll(){
   return this.angularFireDatabase.list(this.specialty);
  }
  push(espec: Especialidad){
    return this.angularFireDatabase.object(this.specialty + '/' + espec.idEspecialidad).set(espec);
  }
  get(uid: any){
    return this.angularFireDatabase.object(this.specialty + '/' + uid);
  }
}
