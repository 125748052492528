import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Admin } from '@services/model/admin.modelo';
import { auth } from 'firebase';
import { AuthenticationService } from '@services/authentication.service';
import { User } from '@shared/interfaces/user';
import { UserService } from '@services/user.service';
import { MsadminService } from '@services/mysql/msadmin.service';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private angularFireDatabase: AngularFireDatabase,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private msadminService: MsadminService

  ) { }

  getAll(){
   return this.angularFireDatabase.list('/admin');
  }
  getById(uid: string){
   return this.angularFireDatabase.object('/admin/' + uid);
  }

  async add(admin: Admin){
    return await this.validarCuenta(admin);
  }

  validarCuenta(admin: Admin) {
    this.getByEmail(admin.email).then(
      (data: any) => {
        if ( data.length > 0 ) {
          alert('administrador ya exite con este email');
        }else{
          this.validarUser(admin);
        }
      }
    );
  }
  validarUser(admin: Admin) {
    const password = '123456';
    this.userService.getByEmail(admin.email).then(
      async (data: any) => {
        if ( data.length > 0 ) {
          admin.uid = data[0].uid;
        }else{
          const session: any = await this.authenticationService.registerWithEmail(admin.email, password);
          admin.uid = session.user.uid;
        }
        this.create(admin);
      }
    );
  }
  create(admin: Admin){
    this.angularFireDatabase.object('/admin/' + admin.uid).set(admin).then(
      (data) => {
        this.msadminService.crear(admin)
        this.authenticationService.recoveryPassword(admin.email);
        alert('administrador creado');
      },
      (error) => {
        alert('error al crear administrador');
      }
    );
  }
  async getByEmail(email: string) {
    return await this.angularFireDatabase.list('/admin', ref => ref.orderByChild('email').equalTo(email));
  }
  update(admin: Admin){
    return this.angularFireDatabase.object('/admin/' + admin.uid).set(admin);
  }



}
