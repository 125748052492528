<mat-sidenav-container class="atencion-container">
  <mat-sidenav #sidenavAdmin mode="side" position="end" class="admin-sidenav"
               [fixedInViewport]="isMobileAdmin" [fixedTopGap]="0"
               [fixedBottomGap]="0">
    <div (click)="sidenavAdmin.toggle()" class="close_modal d-disable-close">X</div>
    <div (click)="sidenavAdmin.toggle()" class="close_modal m-disable-close">
      <p class="arrow-left justify-content-center align-items-center d-inline-flex mb-4">
        <span class="material-icons">keyboard_arrow_left</span> Atras</p>
    </div>
    <div class="container nav-right-atencion">
      <div class="row avatar-row">
        <div class="col-12 col-md-12">
          <h1 class="titulo-medico">Admin</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <form class="form-size" [formGroup]="formadmin">
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="nombres">Nombre</label>
                <input type="text" 
                  [ngClass]="{'alert-danger' : formadmin.get('nombres').invalid }"
                  formControlName="nombres" class="form-control" id="nombres" placeholder="Nombre">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="celular">Celular</label>
                <input type="text" 
                  [ngClass]="{'alert-danger' : formadmin.get('celular').invalid }"
                  formControlName="celular" class="form-control" id="celular" placeholder="Celular">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="email">Correo</label>
                <input type="email"
                [ngClass]="{'alert-danger' : formadmin.get('email').invalid }"
                formControlName="email" class="form-control" id="email" placeholder="Correo">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="estado">Estado</label>
                <select id="estado" formControlName="estado" class="form-control">
                  <option value="activo">activo</option>
                  <option value="inactiva">inactiva</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-6">
          <button class="btn btn-spacing btn-black" (click)="sidenavAdmin.toggle()">Cerrar</button>
        </div>
        <div class="col-6 col-md-6">
          <button class="btn btn-spacing btn-red" (click)="guardar()">Guardar</button>
        </div>
        </div>
    </div>

  </mat-sidenav>
  <mat-sidenav-content [style.margin-right]="sidenavAdmin.opened ? 'auto' : 'auto'">
    <div class="container mt-container">
      <div class="row p-table">
        <div class="col-6 col-md-8 col-xl-8">
          <h1 class="titulo-medico">Admin</h1>
        </div>
        <div class="col-6 col-md-4 col-xl-4 text-center">
          <button class="btn btn-black" (click)="showAdmin({})">Agregar nuevo</button>
        </div>
      </div>
      <div class="row header-p-t p-table">
        <div class="col-12 col-md-12 col-xl-12 ">
          <ng-container *ngTemplateOutlet="tabAtencion"></ng-container>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #tabAtencion>
  <div class="card-header border-0 tabla-titulo font-weight-bold">
    <div class="row">
      <div class="col-3 col-md-3 col-xl-3">
        <p class="mb-0">Nombre</p>
      </div>
      <div class="col-3 col-md-4 col-xl-4">
        <p class="mb-0">Correo</p>
      </div>
      <div class="col-3 col-md-2 col-xl-2">
        <p class="mb-0">Estado</p>
      </div>
      <div class="col-3 col-md-3 col-xl-3 text-center  pl-0 pr-0">
      </div>
    </div>
  </div>
  <div class="scroll-container">
    <div class="card mb-3 tabla-titulo" *ngFor="let ad of admins">
      <div class="card-body">
        <div class="row align-items-center d-flex justify-content-center">
          <div class="col-6 col-md-3 col-xl-3">
            <p>{{ad.nombres}}</p>
          </div>
          <div class="col-6 col-md-4 col-xl-4">
            <p>{{ad.email}}</p>
          </div>
          <div class="col-6 col-md-2 col-xl-2">
            <p class="c-rojo">{{ad.estado}}</p>
          </div>
          <div class="col-6 col-md-3 col-xl-3 text-center  pl-0 pr-0">
            <button class="btn btn-outline-azul" (click)="showAdmin(ad)" >Ver detalle</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
