import { Component, OnInit, ViewChild } from '@angular/core'
import { Especialidad } from '@services/model/especialidad.modelo'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { Medico } from '@services/model/medico.modelo'
import { Paciente } from '@services/model/paciente.modelo'
import { Atencion } from '@services/model/atenciones.modelo'
import { Observable } from 'rxjs'
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Meeting } from '@services/model/zoom.model';

import { FormGroup, FormControl, Validators, FormBuilder, ReactiveFormsModule, FormArray } from '@angular/forms';
import { ExporterService } from '@services/exporter.service'
import { AtencionesService } from '@services/core/atenciones.service'
import { MedicoService } from '@services/core/medico.service'
import { PacienteService } from '@services/core/paciente.service'
import { EspecialidadesService } from '@services/mysql/especialidades.service'

import { SmedicoService } from '@services/core/smedico.service'
import { MspacienteService } from '@services/mysql/mspaciente.service'
import { MsatencionService } from '@services/mysql/msatencion.service'
import { EspecialidadesServicef } from '@services/core/especialidades.service'
import { DateAdapter } from '@angular/material/core'
import { Router } from '@angular/router'
import { map, startWith } from 'rxjs/operators'
import { MsuserService } from '@services/mysql/msuser.service'
import { NgxGalleryThumbnailsComponent } from 'ngx-gallery-9'
import { ZoomService } from '@services/core/zoom.service';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-reporte-pagos',
  templateUrl: './reporte-pagos.component.html',
  styleUrls: ['./reporte-pagos.component.scss']
})
export class ReportePagosComponent implements OnInit {

  modelCita: NgbDateStruct;
  showHorario: boolean;
  fechaSeleccionada: Date;
  horaSelecionada: '';
  horario = [];
  medico: any = {};
  optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  join_url_zoom: string = ""
  start_url_zoom: string = ""
  codigoEspecialidad = ''
  codigoMedico = ''
  especialidades: Especialidad[] = []
  isMobileMedico = true
  validado: number;
  estado: string;

  displayedColumns: string[] = [
    'fecha', 'hora', 'medico', 'celular', 'especialidad', 'paciente', 'celularPaciente', 'estadoDePago', 'detalles', 'metodoDePago'
  ]
  dataSource = new MatTableDataSource<any>()

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  uid: string
  medicos: Medico[] = []
  pacientes: Paciente[] = []
  atenciones: Atencion[] = []
  filteredOptions: Observable<Medico[]>
  myControl = new FormControl()
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  })
  formatencion: FormGroup;
  formcancelar: FormGroup;
  pagoSeleccionado: any = null

  @ViewChild('sidenavPago') sidenavPago

  constructor(
    private fb: FormBuilder,
    private exporterService: ExporterService,
    private atencionesService: AtencionesService,
    private medicoService: MedicoService,
    private pacienteService: PacienteService,
    private especialidadesService: EspecialidadesService,
    private smedicoService: SmedicoService,
    private mspacienteService: MspacienteService,
    private msatencionService: MsatencionService,
    private zoomService: ZoomService,
    private EspecialidadesServicef: EspecialidadesServicef,
    private dadapter: DateAdapter<any>,
    private router: Router,
    private loading: LoadingService
  ) {

    this.dadapter.setLocale('PE')

    this.especialidadesService.listarEspecialidades().then(
      (data: any) => {
        this.especialidades = data;
      }
    )

    this.smedicoService.listar().then(
      (data: any) => {
        this.medicos = data.content;
      }
    )

    this.mspacienteService.listar().then(
      (data: any) => {
        this.pacientes = data.content;
      }
    )
    this.msatencionService.listar().then(
      (data: any) => {
        this.atenciones = data.content;
        this.dataSource = new MatTableDataSource<Atencion>(this.atenciones)
        this.dataSource.paginator = this.paginator
        this.loading.hide();
      }
    )
  }

  ngOnInit(): void {
    this.loading.show();
    this.formatencion = this.fb.group({
      validado: new FormControl('')
    });

    this.formcancelar = this.fb.group({
      cancelado: new FormControl('')
    });

    this.dataSource.paginator = this.paginator

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    )
    const mainHeader = document.getElementById('inifecha')
    mainHeader.focus()

  }

  private _filter(value: string): Medico[] {
    const filterValue = value.toLowerCase()
    return this.medicos.filter(option => option.nombre.toLowerCase().indexOf(filterValue) === 0)
  }

  onSelectionChange() {
    if (this.range.value.end && this.range.value.start) {
      this.getAllAtenciones(this.range.value.start, this.range.value.end)
    }
  }

  exportExcel() {

    let ELEMENT_DATA: Payment[] = []

    interface Payment {
      Fecha: String;
      Hora: String;
      Medico: String;
      Celular: String;
      Especialidad: String;
      Paciente: String;
      CelularP: String;
      Estado: String;
      Metodo: String;
      Costo: String;
    }
    for (let item of this.dataSource.data) {
      let validadoi;
      let customObj = new PeriodicElement();
      customObj.Fecha = item.fecha;
      customObj.Hora = item.hora;
      customObj.Medico = item.medico.nombre
      customObj.Celular = item.medico.celular
      customObj.Especialidad = item.especialidad.description
      customObj.Paciente = item.paciente.persona.nombres + ' ' + item.paciente.persona.apellidos
      customObj.CelularP = item.paciente.celular

      if (item.validado == 1 && item.estado != 'cancelada') {
        validadoi = 'APROBADO'
      } else if (item.validado == 0 && item.estado != 'cancelada') {
        validadoi = 'PENDIENTE'
      } else if (item.validado == 0 && item.estado == 'cancelada') {
        validadoi = 'CANCELADO'

      }

      customObj.Estado = validadoi
      customObj.Metodo = item.payment
      customObj.Costo = item.precio
      ELEMENT_DATA.push(customObj);

    }

    this.exporterService.exposrtToExcel(ELEMENT_DATA, this.codigoMedico)
  }

  addNewDate(eventn: string, event) {
  }

  getAllAtenciones(fechaIni, fechaFin) {
    this.loading.show();
    this.msatencionService.listarfechainifin(fechaIni, fechaFin).then(
      (data: any) => {
        this.atenciones = data.content;
        this.dataSource = new MatTableDataSource<Atencion>(this.atenciones)
        this.dataSource.paginator = this.paginator
        this.loading.hide();
      }
    )

  }

  filtrarporMedico(atens: any[], uid: any) {
    if (uid) {
      return atens.filter(data => data.medicoUid === uid)
    } else {
      return atens
    }
  }

  buscarNombremedico(medicoUid) {
    const medico: any = this.medicos.find(med => med.uid === medicoUid)
    return medico.celular || ''
  }

  buscarNumeroPaciente(pacienteUid) {
    const paciente: any = this.pacientes.find(pac => pac.uid === pacienteUid)
    if (paciente) {
      return paciente.celular
    } else {
      ''
    }
  }

  filtrarporFecha(atens: Atencion[], fechaIni: Date, fechaFin: Date) {
    const fechaIniGMT = new Date(Date.UTC(fechaIni.getFullYear(), fechaIni.getMonth(), fechaIni.getDate()))
    const fechaFinGMT = new Date(Date.UTC(fechaFin.getFullYear(), fechaFin.getMonth(), fechaFin.getDate()))
    return atens.filter(x =>
      new Date((new Date(x.fecha)).toUTCString()) >= fechaIniGMT &&
      new Date((new Date(x.fecha)).toUTCString()) <= fechaFinGMT
    )
  }

  convertJson(data: any[]) {

  }

  seleccionarPago(pago: any) {
    this.medico = pago.medico;

    this.pagoSeleccionado = pago
    this.sidenavPago.toggle()
    this.formatencion.patchValue({
      validado: pago.validado,
    });
    this.formcancelar.patchValue({
      cancelado: pago.estado == "cancelada" ? 1 : 0,
    });
    this.fechaSeleccionada = new Date(pago.fecha);
    this.horaSelecionada = pago.hora
    this.modelCita = { year: this.fechaSeleccionada.getFullYear(), month: this.fechaSeleccionada.getMonth() + 1, day: this.fechaSeleccionada.getDate() + 1 };
    this.cargarHorario({
      year: this.fechaSeleccionada.getFullYear(),
      month: this.fechaSeleccionada.getMonth() + 1,
      day: this.fechaSeleccionada.getDate() + 1
    });

  }
  async guardarvalidadoatencion(id: any) {
    let validado = this.formatencion.value.validado == true ? 1 : 0;
    let estado = this.formcancelar.value.cancelado == true ? "cancelada" : "creada";

    if (estado == "cancelada") {
      validado = 0;
    } else if (this.pagoSeleccionado.payment === 'CULQI' && estado == "creada") {
      validado = 1;

    }
    this.validado = validado;
    this.estado = estado;
    /*await this.msatencionService.actualizarestado(estado, id)
    await this.msatencionService.actualizarvalidado(validado, id)*/
    this.ActualizarAtencion();


  }
  cargarHorario(fecha) {
    this.showHorario = false;
    this.toDateSelection(fecha);


    this.getHoras(this.medico.idMedico, this.fechaSeleccionada);
  }
  toDateSelection(fecha) {
    this.fechaSeleccionada = new Date(fecha.year, fecha.month - 1, fecha.day, 0, 0, 0);


  }
  getHoras(id: string, fecha: Date) {
    const dia = fecha.getDay();
    let dianom;
    if (dia == 0) {
      dianom = "Domingo";
    } else if (dia == 1) {
      dianom = "Lunes";

    } else if (dia == 2) {
      dianom = "Martes";

    } else if (dia == 3) {
      dianom = "Miercoles";

    } else if (dia == 4) {
      dianom = "Jueves";

    } else if (dia == 5) {
      dianom = "Viernes";

    } else if (dia == 6) {
      dianom = "Sabado";
    }
    const fechaDir = fecha.toLocaleDateString('fr-CA');

    let newHorario = [];
    this.smedicoService.listarhorariodisponible(id, fecha).then(
      (cronograma: any) => {
        if (cronograma) {
          this.showHorario = true;
          this.horario = cronograma
          let fechcita: any = {
            estado: "online",
            hora: this.pagoSeleccionado.hora,
          }

          if (fechaDir == this.pagoSeleccionado.fecha) {
            if (this.pagoSeleccionado.validado == 1) {
              this.horario.push(fechcita)
            }
          }

        }
      }
    );
  }
  badgeCita(hora: any) {
    this.horaSelecionada = hora;
  }
  ActualizarAtencion(): void {
    //this.showOverlay = true;
    this.loading.show();
    const meeting: Meeting = {
      topic: 'Cita del paciente : ' + this.pagoSeleccionado.paciente.persona.nombres + ' ' + this.pagoSeleccionado.paciente.persona.apellidos,
      type: 2,
      stat_date: this.fechaSeleccionada.toLocaleDateString('fr-CA'),
      stat_time: this.horaSelecionada + ':00',
      duration: "60",
      password: ""
    };
    this.zoomService.getMeeting(meeting)
      .subscribe(async response => {
        this.join_url_zoom = response.join_url;
        this.start_url_zoom = response.start_url;
        await this.saveAtention();
      }, async (error: any) => {
        this.loading.hide();
        //this.showOverlay = false;
        alert("Upps, tenemos dificultades para crear la cita, vuelva a intentar");
        return false;
      });


  }

  async saveAtention() {

    const atencion: any = {
      fecha: this.fechaSeleccionada,
      hora: this.horaSelecionada,
      id_tipo_atencion: this.pagoSeleccionado.id_tipo_atencion,
      estado: this.estado,
      precio: this.pagoSeleccionado.medico.precio,
      payment: this.pagoSeleccionado.payment,
      validado: this.validado,
      encuesta: 0,
      imgyape: this.pagoSeleccionado.imgyape,
      motivo: this.pagoSeleccionado.motivo,
      idEspecialidad: this.pagoSeleccionado.idEspecialidad,
      id_paciente: this.pagoSeleccionado.id_paciente,
      id_medico: this.pagoSeleccionado.id_medico,
      join_url: this.join_url_zoom,
      start_url: this.start_url_zoom,
      idAtencion: this.pagoSeleccionado.idAtencion
    };
    await this.msatencionService.actualizar(atencion);
    let data = await this.msatencionService.listar();
    await this.loading.hide();
    this.atenciones = data.content;
    this.dataSource = new MatTableDataSource<Atencion>(this.atenciones)
    this.dataSource.paginator = this.paginator
    this.sidenavPago.toggle();
    //this.showOverlay = false;
    alert("Actualizado");

  }
}
export class PeriodicElement {
  Fecha: String;
  Hora: String;
  Medico: String;
  Celular: String;
  Especialidad: String;
  Paciente: String;
  CelularP: String;
  Estado: String;
  Metodo: String;
  Costo: String;
}
