import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AdminService } from '@services/core/admin.service';
import { MsadminService } from '@services/mysql/msadmin.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Admin } from '@services/model/admin.modelo';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('sidenavAdmin') sidenavAdmin: MatSidenav;
  isMobileAdmin = true;
  formadmin: FormGroup;
  admins: Admin[] = [];
  idadmin: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private deviceService: DeviceDetectorService,
    private adminService: AdminService,
    private msadminService: MsadminService,
    private loading: LoadingService

  ) {
    /*this.adminService.getAll().valueChanges().subscribe(
      (data) => {
        this.admins = data || [];
        console.log(data);
      }
    );*/
    this.msadminService.listar().then(
      (data) => {
        this.admins = data.content || [];
        this.loading.hide();
      }
    );
    this.detectorAdmin();
    this.formadmin = this.fb.group({
      uid: new FormControl(''),
      celular: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required]),
      nombres: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.loading.show();
  }

  showAdmin(admin) {
    this.formadmin.patchValue({
      uid: admin.uid,
      celular: admin.celular || '',
      email: admin.email,
      nombres: admin.nombres,
      estado: admin.estado
    });
    this.idadmin = admin.idAdministrador;
    this.sidenavAdmin.toggle();
  }

  detectorAdmin() {
    const isdeviceMobile = this.deviceService.isMobile();
    isdeviceMobile === true ? this.isMobileAdmin = false : this.isMobileAdmin = true;
  }

  guardar() {
    this.loading.show();
    if (this.formadmin.value.uid) {
      this.update();
    } else {
      this.crear();
    }
  }
  async crear() {
    await this.adminService.add(this.formadmin.value)
    this.loading.hide();
    setTimeout(() => {                           // <<<---using ()=> syntax
      this.msadminService.listar().then(
        (data) => {
          this.admins = data.content || [];
        }
      );
    }, 3000)
  }
  update() {
    let adminup: any = {
      "celular": this.formadmin.value.celular,
      "email": this.formadmin.value.email,
      "estado": this.formadmin.value.estado,
      "idAdministrador": this.idadmin,
      "nombres": this.formadmin.value.nombres,
      "uid": this.formadmin.value.uid
    }
    this.msadminService.actualizar(adminup)
    this.adminService.update(this.formadmin.value).then(
      () => {
        this.loading.hide();
        alert('administrador actualizado');
      }
    );
    setTimeout(() => {                           // <<<---using ()=> syntax
      this.msadminService.listar().then(
        (data) => {
          this.admins = data.content || [];
        }
      );
    }, 1000);


  }
}
