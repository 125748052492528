import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmedicoService {

  constructor(
    private http: HttpClient
  ) { }

  async listar(){
    return await this.http.get<any>(apiServer + 'medico/page/0/999').toPromise()
  }

  async listarxespecialidad(idespecialidad){
    return await this.http.get<any>(apiServer + 'medico/especialidad/' + idespecialidad + '/0/999').toPromise()
  }
  async listarxnombre(nombre){
    return await this.http.get<any>(apiServer + 'medico/nombre/' + nombre).toPromise()
  }
  async listarxid(id){
    return await this.http.get<any>(apiServer + 'medico/id/' + id).toPromise()
  }
  async listarxndocumento(ndocumento){
    return await this.http.get<any>(apiServer + 'medico/dni/' + ndocumento).toPromise()
  }


  async crear(datos){
    return await this.http.post<any>(apiServer + 'medico', datos).toPromise()
  }

  async actualizar(datos){
    return await this.http.put<any>(apiServer + 'medico/' + datos.idMedico, datos).toPromise()
  }
  async listarhorario(id){
    return await this.http.get<any>(apiServer + 'horario/'+id).toPromise()
  }

  async registrarhorario(datos){
    return await this.http.post<any>(apiServer + 'horario', datos).toPromise()
  }
  async actualizarhorario(datos){
    return await this.http.put<any>(apiServer + 'horario', datos).toPromise()
  }
  async eliminarhorariofrommedico(idmedico){
    return await this.http.get<any>(apiServer + 'medico/deletehorariofrommedico/'+idmedico).toPromise()
  }
  async listarhorariodisponible(id,fecha){
    return await this.http.get<any>(apiServer + 'horario/horariodisponible/'+id+'/'+fecha).toPromise()
  }
}
