import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MspersonaService {

  constructor(
    private http: HttpClient
  ) { }

  async crear(datos) {
    return await this.http.post<any>(apiServer + 'persona', datos).toPromise()
  }
  async listarxid(id) {
    return await this.http.get<any>(apiServer + 'persona/'+ id).toPromise()
  }

  async actualizar(datos) {
    return await this.http.put<any>(apiServer + 'persona/' + datos.idPersona, datos).toPromise()
  }
  async listarxdni(dni){
    return await this.http.get<any>(apiServer + 'persona/dni/' + dni).toPromise()
  }

}
