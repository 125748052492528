import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EspecialidadesService {

  constructor(
    private http: HttpClient
  ) { }

  async listarEspecialidades(){
    return await this.http.get<any>(apiServer + 'especialidad').toPromise()
  }

  async crear(datos){
    return await this.http.post<any>(apiServer + 'especialidad', datos).toPromise()
  }

  async actualizar(datos){
    return await this.http.put<any>(apiServer + 'especialidad/' + datos.idEspecialidad, datos).toPromise()
  }
  async eliminar(idEspecialidad){
    return await this.http.get<any>(apiServer + 'especialidad/eliminacionlogicaesp/'+idEspecialidad).toPromise()
  }
  async listar() {
    return await this.http.get<any>(apiServer + 'especialidad/listaractivos').toPromise()
  }
}