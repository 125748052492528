import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PacienteService } from '@services/core/paciente.service';
import { MspacienteService } from '@services/mysql/mspaciente.service';
import { Persona } from '@services/model/persona.modelo';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { UserService } from '@services/user.service';
import { EnfermedadesService } from '@services/mysql/enfermedades.service';
import { EnfermedadesServicef } from '@services/core/enfermedades.service';
import { AuthenticationService } from '@services/authentication.service';
import { AtencionesService } from '@services/core/atenciones.service';

import { Enfermedad } from '@services/model/enfermedad.modelo';
import { MatDialog } from '@angular/material/dialog';
import { ModalExamenComponent } from '@template/modals/modal-examen/modal-examen.component';
import { formatDate } from '@angular/common';
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.scss']
})
export class PacienteComponent implements OnInit {
  @ViewChild('sidenavPaciente') sidenavPaciente: MatSidenav;
  isMobilePaciente = true;
  modelPaciente: NgbDateStruct;
  personas: Persona[] = [];
  paciente: any;
  personaSelecct: Persona;
  formpersona: FormGroup;
  formpaciente: FormGroup;
  formuser: FormGroup;
  enfermedades: string[] = [];
  enfermedadesProvider: Enfermedad[] = [];
  examenes: any[] = [];
  activeEnfer = false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private pacienteService: PacienteService,
    private mspacienteService: MspacienteService,
    private emfermedadesService: EnfermedadesService,
    private EnfermedadesServicef: EnfermedadesServicef,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private atencionesService: AtencionesService,
    public dialogInfo: MatDialog,
    private loading: LoadingService

  ) {
    this.detectorPaciente();
  }

  ngOnInit(): void {
    this.loading.show();
    /*this.EnfermedadesServicef.getAll().valueChanges().subscribe(
      (data: any) => {
        this.enfermedadesProvider = data;
      }
    );*/
    this.emfermedadesService.listar().then(
      (data: any) => {
        this.enfermedadesProvider = data.content;
      }
    );
    this.formuser = this.fb.group({
      uid: new FormControl(''),
      email: new FormControl(''),
      provider: new FormControl('')
    });

    this.formpersona = this.fb.group({
      uid: new FormControl(''),
      tipoDocumento: new FormControl(''),
      numeroDocumento: new FormControl(''),
      nombres: new FormControl(''),
      apellidos: new FormControl(''),
      fechaNacimiento: new FormControl(''),
      sexo: new FormControl('')
    });
    this.formpaciente = this.fb.group({
      celular: new FormControl(''),
      direccion: new FormControl(''),
    });

    this.cargarPacientes()
  }

  showPaciente(persona) {
    /*let fechan= new Date(persona.persona.fechaNacimiento);
    console.log(fechan)*/
    let fechana = formatDate(persona.persona.fechaNacimiento, 'yyyy-MM-dd', 'es-PE', 'UTC');
    this.personaSelecct = persona;
    this.formpersona.patchValue({
      uid: persona.uid,
      nombres: persona.persona.nombres,
      apellidos: persona.persona.apellidos,
      tipoDocumento: persona.persona.id_tipo_documento,
      numeroDocumento: persona.persona.numeroDocumento,
      sexo: persona.persona.sexo,
      fechaNacimiento: fechana
    });
    this.userService.getById(this.personaSelecct.uid).valueChanges().subscribe(
      (userdata: any) => {
        if (userdata) {
          this.formuser.patchValue({
            uid: userdata.uid,
            email: userdata.email,
            provider: userdata.provider
          });
        }
      }
    );

    this.pacienteService.getById(this.personaSelecct.uid).valueChanges().subscribe(
      (pacientedata: any) => {
        if (pacientedata) {
          this.formpaciente.patchValue({
            celular: pacientedata.celular,
            direccion: pacientedata.direccion,
            datosMedicos: pacientedata.datosMedicos
          });

          this.activeEnfer = false;
          this.enfermedades = pacientedata.datosMedicos || [];
          this.activeEnfer = true;
        }
      }
    );

    this.atencionesService.getAllPacienteExamenes(persona.uid).valueChanges().subscribe(
      (data) => {
        this.examenes = data;
        //this.sortExamenes();
      }
    );

    this.sidenavPaciente.toggle();
  }

  detectorPaciente() {
    const isdeviceMobile = this.deviceService.isMobile();
    isdeviceMobile === true ? this.isMobilePaciente = false : this.isMobilePaciente = true;
  }

  filtarPaciene() {
    this.loading.show();
    const txtapellidos: any = document.getElementById('txtapellidos');
    const ape: string = txtapellidos.value;
    /*this.pacienteService.findByApellidos(ape.toUpperCase()).valueChanges().subscribe(
      (data: any) => {
        this.personas = data;
      }
    );*/
    if (ape.length == 0) {
      this.cargarPacientes();
    } else {
      this.mspacienteService.listarxapellidos(ape).then(
        (data: any) => {
          this.paciente = data;
          this.loading.hide();
        }
      );
    }

  }
  filtarPacienenroDocumento() {
    this.loading.show();
    const txtnrodocumento: any = document.getElementById('txtnrodocumento');
    const nrod: string = txtnrodocumento.value;
    /*this.pacienteService.findByApellidos(ape.toUpperCase()).valueChanges().subscribe(
      (data: any) => {
        this.personas = data;
      }
    );*/
    if (nrod.length == 0) {
      this.cargarPacientes();
    } else {
      this.mspacienteService.listarxnroDocumento(nrod).then(
        (data: any) => {
          this.paciente = data;
          this.loading.hide();
        }
      );
    }

  }
  async cargarPacientes() {
    let data = await this.mspacienteService.listar()
    this.paciente = data.content
    this.loading.hide();
  }

  modalExamen(paciente) {
    this.dialogInfo.open(ModalExamenComponent, {
      width: '600px',
      height: '220px',
      data: {
        uid: paciente.uid
      }
    });

  }

}