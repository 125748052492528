import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TemplateModule } from '@template/template.module';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { RouterModule } from '@angular/router';
import { MedicoComponent } from './medico/medico.component';
import { PacienteComponent } from './paciente/paciente.component';
import { PagoComponent } from './pago/pago.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { AdminComponent } from './admin/admin.component';
import { RptMedicosComponent } from './reportes/medicos/rptmedicos.component';
import { Rpt01Component } from './reportes/reporte01/rpt01.component';
import { ReportePagosComponent } from '@pages/reportes/reporte-pagos/reporte-pagos.component';
import { EspecialidadesComponent } from './especialidades/especialidades.component';
import { EnfermedadesComponent } from './enfermedades/enfermedades.component';
import { CitaComponent } from './cita/cita.component';
import { EncuestaComponent } from './encuesta/encuesta.component';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';
import { EncuestaatencionComponent } from './encuestaatencion/encuestaatencion.component'

const ModuleComponent = [
  RptMedicosComponent,
  Rpt01Component,
  MedicoComponent,
  PacienteComponent,
  CitaComponent,
  PagoComponent,
  EmpresaComponent,
  AdminComponent,
  EspecialidadesComponent,
  EnfermedadesComponent,
  EncuestaComponent,
  EncuestaatencionComponent
];

@NgModule({
  declarations:
    [
      ModuleComponent,
      ReportePagosComponent,
      EspecialidadesComponent,
      EnfermedadesComponent,
      CitaComponent,
      EncuestaComponent,
      PublicacionesComponent
    ],
  imports:
    [
      CommonModule,
      NgbModule,
      FormsModule,
      ReactiveFormsModule,
      MaterialModule,
      MatDialogModule,
      TemplateModule,
      NgxFullCalendarModule,
      NgxGalleryModule,
      RouterModule
    ],
  exports: [
    ModuleComponent
  ],
  providers: [TemplateModule],
  entryComponents: []
})
export class PagesModule {


}
