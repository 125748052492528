import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MsuserService {

  constructor(
    private http: HttpClient
  ) { }
  async crear(datos) {
    return await this.http.post<any>(apiServer + 'user', datos).toPromise()
  }
  async listarxid(id) {
    return await this.http.get<any>(apiServer + 'user/'+ id).toPromise()
  }
  async listarxusername(username) {
    return await this.http.get<any>(apiServer + 'user/username/'+ username).toPromise()
  }

  async actualizar(datos) {
    return await this.http.put<any>(apiServer + 'user/' + datos.id, datos).toPromise()
  }
}
