<mat-sidenav-container class="atencionrp-container">

  

  <mat-sidenav-content style="margin-right: auto">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-12 col-xl-12 ">
          <h1 class="titulo-perfil">Reporte de atenciones por estado</h1>
        </div>
        <div class="col-12 col-md-12 col-xl-12">
          <mat-form-field appearance="fill" [formGroup]="range">
            <mat-label>Fecha inicial</mat-label>
            <input matInput [matDatepicker]="dpini" formControlName="start" id="inifecha"
              (dateChange)="onSelectionChange()">
            <mat-datepicker-toggle matSuffix [for]="dpini"></mat-datepicker-toggle>
            <mat-datepicker #dpini></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" [formGroup]="range">
            <mat-label>Fecha final</mat-label>
            <input matInput [matDatepicker]="dpfin" formControlName="end" (dateChange)="onSelectionChange()">
            <mat-datepicker-toggle matSuffix [for]="dpfin"></mat-datepicker-toggle>
            <mat-datepicker #dpfin></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row scroll-container" style="overflow-x: auto;">
        <div class="col-12">
          <table mat-table [dataSource]="dataSource">

            <!-- Position Column -->
            <ng-container matColumnDef="fecha" sticky>
              <th mat-header-cell *matHeaderCellDef> Fecha </th>
              <td mat-cell *matCellDef="let element" > {{element.fecha}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="hora">
              <th mat-header-cell *matHeaderCellDef > Hora </th>
              <td mat-cell *matCellDef="let element" > {{element.hora}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="medico">
              <th mat-header-cell *matHeaderCellDef > Médico </th>
              <td mat-cell *matCellDef="let element" > {{element.medico.nombre}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="celular">
              <th mat-header-cell *matHeaderCellDef > Celular </th>
              <td mat-cell *matCellDef="let element" > {{element.medico.celular}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="especialidad">
              <th mat-header-cell *matHeaderCellDef > Especialidad </th>
              <td mat-cell *matCellDef="let element" > {{element.especialidad.description}}
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="paciente">
              <th mat-header-cell *matHeaderCellDef > Paciente </th>
              <td mat-cell *matCellDef="let element" > {{element.paciente.persona.nombres}}
                {{element.paciente.persona.apellidos}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="celularPaciente">
              <th mat-header-cell *matHeaderCellDef > Celular </th>
              <td mat-cell *matCellDef="let element" > {{element.paciente.celular}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef > Estado </th>
              <td mat-cell *matCellDef="let element" > {{element.estado}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5, 30]" showFirstLastButtons></mat-paginator>
        </div>
        <div>
          <br>
          <button class="btn btn-outline-azul" (click)="exportExcel()">Export Excel</button>
        </div>
      </div>
    </div>

  </mat-sidenav-content>

</mat-sidenav-container>
