import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@services/core/configuration.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MsempresaService } from '@services/mysql/msempresa.service'
import { LoadingService } from '@services/Loading/loading.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {
  formempresa: FormGroup;
  imagenSelect: any;
  fotoimagen: any;
  idEmpresa: any;
  constructor(
    private configurationService: ConfigurationService,
    private msempresaService: MsempresaService,
    private loading: LoadingService

  ) {
    this.formempresa = new FormGroup({
      logo: new FormControl(''),
      razonsocial: new FormControl(''),
      ruc: new FormControl(''),
      direccion: new FormControl(''),
      telefono: new FormControl('')
    });
  }

  ngOnInit(): void {
    /*this.configurationService.getEmpresa().valueChanges().subscribe(
      (data: any) => {
        if ( data ) {
          this.formempresa.patchValue({
            logo: data.logo,
            razonsocial: data.razonsocial,
            ruc: data.ruc,
            direccion: data.direccion,
            telefono: data.telefono
          });
          this.fotoimagen = data.logo;
        }
      }
    );*/
    this.loading.show();
    this.msempresaService.listar().then(
      (data: any) => {
        this.loading.hide();
        if ( data ) {
          this.formempresa.patchValue({
            logo: data.content[0].logo,
            razonsocial: data.content[0].razonsocial,
            ruc: data.content[0].ruc,
            direccion: data.content[0].direccion,
            telefono: data.content[0].telefono
          });
          this.fotoimagen = data.content[0].logo;
          this.idEmpresa = data.content[0].idEmpresa
        }
      }
    );
  }
  cargarimagen(event){
    const reader = new FileReader();
    reader.onload = () => {
        this.fotoimagen = reader.result;
    };
    if ( event.target.files ) {
      reader.readAsDataURL(event.target.files[0]);
      this.imagenSelect = event.target.files[0].name;
    }
  }
  guardar(){
    this.loading.show();
    if ( this.fotoimagen ) { this.formempresa.value.logo = this.fotoimagen; }
    /*this.configurationService.setEmpresa(this.formempresa.value).then(
      () => {
        alert('registrado exitosamente');
      }
    ).catch(
      (error) => {
        console.log(error);
      }
    );*/
    this.msempresaService.actualizar(this.idEmpresa,this.formempresa.value).then(
      () => {
        this.loading.hide();
        alert('registrado exitosamente');
      }
    ).catch(
      (error) => {
        this.loading.hide();
        console.log(error);
      }
    );
  }
}
