import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicacionService {

  constructor(
    private http: HttpClient
  ) { }

  async listar(){
    return await this.http.get<any>(apiServer + 'publicacion/page/0/999').toPromise()
  }

  async crear(datos){
    return await this.http.post<any>(apiServer + 'publicacion', datos).toPromise()
  }

  async actualizar(datos){
    return await this.http.put<any>(apiServer + 'publicacion/' + datos.idPublicacion, datos).toPromise()
  }

  async eliminar(idPublicacion){
    return await this.http.delete<any>(apiServer + 'publicacion/' + idPublicacion).toPromise()
  }
}
