import { Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { FileUpload } from '@template/modals/modal-examen/file-upload';
import { FileUploadService } from '@services/file-upload.service';

@Component({
  selector: 'app-modal-examen',
  templateUrl: './modal-examen.component.html',
  styleUrls: ['./modal-examen.component.scss']
})
export class ModalExamenComponent implements OnInit {

  selectedFiles: FileList;
  currentFileUpload: FileUpload;
  percentage: number;
  uid: string;
  constructor(
    public dialogRef: MatDialogRef<ModalExamenComponent>,
    private uploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public data: any)
    {
      this.uid = data.uid;
    }

  ngOnInit(): void {
  }

  selectFile(event): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    const file = this.selectedFiles.item(0);
    this.selectedFiles = undefined;
    this.currentFileUpload = new FileUpload(file);
    this.uploadService.pushFileToStorage(this.currentFileUpload,this.uid).subscribe(
      percentage => {
        this.percentage = Math.round(percentage);
        if(this.percentage == 100){
          setTimeout(() => {
            this.dialogRef.close();
          }, 3000);

        }
      },
      error => {
        console.log(error);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
