import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ExporterService } from '@services/exporter.service';
import { AtencionesService } from '@services/core/atenciones.service';
import { MedicoService } from '@services/core/medico.service';
import { PacienteService } from '@services/core/paciente.service';
import { Medico } from '@services/model/medico.modelo';
import { Atencion } from '@services/model/atenciones.modelo';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Especialidad } from '@services/model/especialidad.modelo';
import { EspecialidadesService } from '@services/mysql/especialidades.service';
import { EspecialidadesServicef } from '@services/core/especialidades.service';

import { SmedicoService } from '@services/core/smedico.service'
import { MspacienteService } from '@services/mysql/mspaciente.service'
import { MsatencionService } from '@services/mysql/msatencion.service'
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { NavigationEnd, Router } from '@angular/router';
import { Paciente } from '@services/model/paciente.modelo';
import { LoadingService } from '@services/Loading/loading.service';


@Component({
  selector: 'app-rptmedicos',
  templateUrl: './rpt01.component.html',
  styleUrls: ['./rpt01.component.scss']
})
export class Rpt01Component implements OnInit, AfterViewInit {
  codigoEspecialidad = '';
  codigoMedico = '';
  especialidades: Especialidad[] = [];

  displayedColumns: string[] = ['fecha', 'hora', 'medico', 'celular', 'especialidad', 'paciente', 'celularPaciente', 'estado'];
  dataSource = new MatTableDataSource<any>()

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  uid: string;
  medicos: Medico[] = [];
  pacientes: Paciente[] = [];
  atenciones: Atencion[] = [];
  filteredOptions: Observable<Medico[]>;
  myControl = new FormControl();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  constructor(
    private exporterService: ExporterService,
    private atencionesService: AtencionesService,
    private medicoService: MedicoService,
    private pacienteService: PacienteService,
    private especialidadesService: EspecialidadesService,
    private EspecialidadesServicef: EspecialidadesServicef,
    private smedicoService: SmedicoService,
    private mspacienteService: MspacienteService,
    private msatencionService: MsatencionService,
    private dadapter: DateAdapter<any>,
    private router: Router,
    private loading: LoadingService

  ) {
    this.dadapter.setLocale('Es');
    this.especialidadesService.listarEspecialidades().then(
      (data: any) => {
        this.especialidades = data;
      }
    );

    this.smedicoService.listar().then(
      (data: any) => {
        this.medicos = data.content;
      }
    );

    this.mspacienteService.listar().then(
      (data: any) => {
        this.pacientes = data.content;
      }
    );

    this.msatencionService.listar().then(
      (data: any) => {
        this.atenciones = data.content;
        this.dataSource = new MatTableDataSource<Atencion>(this.atenciones)
        this.dataSource.paginator = this.paginator
        this.loading.hide();
      }
    )
  }

  ngOnInit(): void {
    this.loading.show();

    this.dataSource.paginator = this.paginator;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    //this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
    const mainHeader = document.getElementById('inifecha');
    //if (mainHeader) {
    mainHeader.focus();
    //}
    //});
  }

  ngAfterViewInit() {
    //document.getElementById('inifecha').focus();
  }

  private _filter(value: string): Medico[] {
    const filterValue = value.toLowerCase();
    return this.medicos.filter(option => option.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  onSelectionChange() {
    if (this.range.value.end && this.range.value.start) {
      this.getAllAtenciones(this.range.value.start, this.range.value.end)
    }
  }
  exportExcel() {

    let ELEMENT_DATA: Payment[] = []

    interface Payment {
      Fecha: String;
      Hora: String;
      Medico: String;
      Celular: String;
      Especialidad: String;
      Paciente: String;
      CelularP: String;
      Estado: String;

    }

    for (let item of this.dataSource.data) {
      //this.dataSource.data.forEach(item => {

      let customObj = new PeriodicElement();
      customObj.Fecha = item.fecha;
      customObj.Hora = item.hora;
      customObj.Medico = item.medico.nombre
      customObj.Especialidad = item.especialidad.description
      customObj.Paciente = item.paciente.persona.nombres + ' ' + item.paciente.persona.apellidos
      customObj.CelularP = item.paciente.celular
      customObj.Estado = item.estado
      ELEMENT_DATA.push(customObj);

    }
    //);

    this.exporterService.exposrtToExcel(ELEMENT_DATA, this.codigoMedico)
  }

  addNewDate(eventn: string, event) {
  }

  getAllAtenciones(fechaIni, fechaFin) {
    this.loading.show();
    this.msatencionService.listarfechainifin(fechaIni, fechaFin).then(
      (data: any) => {
        this.atenciones = data.content;
        this.dataSource = new MatTableDataSource<Atencion>(this.atenciones)
        this.dataSource.paginator = this.paginator
        this.loading.hide();
      }
    )
    /*let result = [];
    this.atencionesService.getReporte01(fechaIni.toISOString().split('T')[0], fechaFin.toISOString().split('T')[0], uid).valueChanges().subscribe(
      (data: any) => {
        console.info( data );
        data.forEach(element => {
          console.info(element);
          const telefono = this.buscarNombremedico(element.medicoUid);
          const telefonoPaciente = this.buscarNumeroPaciente(element.pacienteUid);
          result.push({
            medicoUid: element.medicoUid,
            fecha: element.fecha,
            hora: element.hora,
            medico: element.nombreMedico,
            especialidad: element.especialidad,
            celular: telefono,
            celularPaciente: telefonoPaciente,
            paciente: element.paciente,
            estado: element.estado
          });
        });
        // result = this.filtrarporMedico(result, uid);
        this.convertJson(result);
      }
    );*/
  }

  filtrarporMedico(atens: any[], uid: any) {
    if (uid) {
      return atens.filter(data => data.medicoUid === uid);
    } else {
      return atens;
    }
  }

  buscarNombremedico(medicoUid) {
    const medico: any = this.medicos.find(med => med.uid === medicoUid);
    return medico.celular || '';
  }

  buscarNumeroPaciente(pacienteUid) {
    console.info(pacienteUid);
    const paciente: any = this.pacientes.find(pac => pac.uid === pacienteUid);
    if (paciente) {
      return paciente.celular
    } else {
      ''
    }
  }

  filtrarporFecha(atens: Atencion[], fechaIni: Date, fechaFin: Date) {
    const fechaIniGMT = new Date(Date.UTC(fechaIni.getFullYear(), fechaIni.getMonth(), fechaIni.getDate()));
    const fechaFinGMT = new Date(Date.UTC(fechaFin.getFullYear(), fechaFin.getMonth(), fechaFin.getDate()));
    return atens.filter(x =>
      new Date((new Date(x.fecha)).toUTCString()) >= fechaIniGMT &&
      new Date((new Date(x.fecha)).toUTCString()) <= fechaFinGMT
    );
  }

  convertJson(data: any[]) {
    /*ELEMENT_DATA = [];
    data.forEach(element => {
      ELEMENT_DATA.push({
        medicoUid: element.medicoUid,
        fecha: element.fecha,
        hora: element.hora,
        medico: element.medico,
        celular: element.celular,
        celularPaciente: element.celularPaciente,
        especialidad: element.especialidad,
        paciente: element.paciente,
        estado: element.estado
      });
    });
*/
    //this.dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    // this.dataSource.paginator = this.paginator;
  }

}

export class PeriodicElement {
  Fecha: String;
  Hora: String;
  Medico: String;
  Celular: String;
  Especialidad: String;
  Paciente: String;
  CelularP: String;
  Estado: String;
}
/*
export interface PeriodicElement {
  medicoUid: string;
  fecha: string;
  hora: string;
  medico: string;
  celular: string;
  celularPaciente: string;
  especialidad: string;
  paciente: string;
  estado: string;
}

let ELEMENT_DATA: PeriodicElement[] = [];*/
