<mat-toolbar class="navigation-header">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center nav-home-desktop">
      <div class="col-md-3 col-xl-2">
        <a class="navbar-brand" (click)="inicioNav()"><img src="assets/img/logo.svg" class="img-fluid img-logo"  alt="logo"/></a>
      </div>
      <div class="col-md-6 col-xl-7">
        <ng-container *ngTemplateOutlet="formSearch"></ng-container>
      </div>
      <div class="col-md-3 col-xl-3">
        <ul class="navbar-nav text-center header-nav">
          <li class="nav-item active">
            <a class="nav-link">{{fecha.toLocaleDateString('pe-PE', optionsDate)}}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row nav-home-mobile">
      <div class="col-4 col-md-3 pl-0">
        <a class="navbar-brand" (click)="inicioNav()"><img src="assets/img/logo.svg" class="img-fluid img-logo"
                                                                   alt="logo"/></a>
      </div>
      <div class="col-8 col-md-9 pr-0 col-xl-9">
        <button class="navbar-toggler" type="button" (click)="close()">
          &#9776;
        </button>
        <!--<form class="search-form">
          <div class="form-group has-feedback col-8">
            <label for="search" class="sr-only">Search</label>
            <input type="text" class="form-control" name="search" id="search" placeholder="search">
            <span class="material-icons form-control-feedback">search</span>
          </div>
        </form>-->
      </div>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container class="header-container" [hasBackdrop]="hasBackdrop">
  <mat-sidenav #sidenav mode="side" class="header-sidenav"
               [fixedInViewport]="false" [fixedTopGap]="0"
               [fixedBottomGap]="0" [position]="position" style="height: calc(100vh - (3.5rem + 1px));">
    <ul class="lista-nav">
      <li (click)="opcionAdmin(0)"><a>Médico</a></li>
      <li (click)="opcionAdmin(1)"><a>Paciente</a></li>
      <li (click)="opcionAdmin(2)"><a>Tipo de atención</a></li>
      <li (click)="opcionAdmin(6)"><a>Especialidades</a></li>
      <li (click)="opcionAdmin(7)"><a>Enfermedades</a></li>

      <li (click)="opcionAdmin(10)"><a>Encuesta</a></li>
      <li (click)="opcionAdmin(13)"><a>Encuestas Atenciones</a></li>
      <li (click)="opcionAdmin(11)"><a>Publicaciones</a></li>

      <li (click)="opcionAdmin(3)"><a>Config. de pago</a></li>
      <li (click)="opcionAdmin(4)"><a>Datos de la empresa</a></li>
      <li (click)="opcionAdmin(8)"><a>Reporte de atenciones</a></li>
      <li (click)="opcionAdmin(9)"><a>Reporte de atenciones por estado</a></li>

      <li (click)="opcionAdmin(12)"><a>Reporte de pago de citas</a></li>

      <li (click)="opcionAdmin(5)"><a>Admin</a></li>
      <li (click)="openModalLogin()"><a>Cerrar sesión</a></li>
    </ul>
    <ul>
    </ul>
  </mat-sidenav>

  <mat-sidenav-content [style.margin-right]="sidenav.opened ? 'auto' : 'auto'" [style.margin-left]="sidenav.opened ? marginAuto : 'auto'">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #formSearch>
  <form class="form-inline my-2 my-lg-0 input-general" *ngIf="false">
    <div class="form-row search">
      <div class="input-group col-12 mr-sm-2">
        <input type="text" class="form-control " placeholder="Buscar........"
               aria-label="Recipient's username"
               aria-describedby="basic-addon2">
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2"><span class="material-icons">search</span></span>
        </div>
      </div>
    </div>
  </form>
</ng-template>