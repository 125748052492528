import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Persona } from '../model/persona.modelo';

@Injectable({
  providedIn: 'root'
})
export class PersonasService {

  constructor(private angularFireDatabase: AngularFireDatabase) { }

  getAll(){
   return this.angularFireDatabase.list('/personas');
  }
  getById(uid: any){
   return this.angularFireDatabase.object('/personas/' + uid);
  }
  create(persona: Persona){
    return this.angularFireDatabase.object('/personas/' + persona.uid).set(persona);
  }
  update(persona: Persona){
    
    return this.angularFireDatabase.object('/personas/' + persona.uid).set(persona);
  }
}
