import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MspacienteService {

  constructor(
    private http: HttpClient
  ) { }

  async listar(){
    return await this.http.get<any>(apiServer + 'paciente/page/0/9999').toPromise()
  }
  async listarxnombres(nombres){
    return await this.http.get<any>(apiServer + 'paciente/nombre/'+nombres).toPromise()
  }
  async listarxapellidos(apellidos){
    return await this.http.get<any>(apiServer + 'paciente/apellido/'+apellidos).toPromise()
  }
  async listarxnroDocumento(numeroDocumento){
    return await this.http.get<any>(apiServer + 'paciente/numeroDocumento/'+numeroDocumento).toPromise()
  }
}
