import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Medico, Horario } from '../model/medico.modelo';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@services/authentication.service';
import { UserService } from '@services/user.service';
import { Persona } from '@services/model/persona.modelo';
import { PersonasService } from './personas.service';
import { User } from '@shared/interfaces/user';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';
import { SmedicoService } from '@services/core/smedico.service';
import { MspersonaService } from '@services/mysql/mspersona.service';
import { MsuserService } from '@services/mysql/msuser.service';

@Injectable({
  providedIn: 'root'
})
export class MedicoService {

  constructor(
    private angularFireDatabase: AngularFireDatabase,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private personasService: PersonasService,
    private http: HttpClient,
    private SmedicoService: SmedicoService,
    private mspersonasService: MspersonaService,
    private msuserService: MsuserService

  ) { }

  getAll() {
    return this.angularFireDatabase.list('/medicos');
  }

  getById(uid: string) {
    return this.angularFireDatabase.object('/medicos/' + uid);
  }

  async create(medico: Medico, user: User, persona: any) {
    return await this.angularFireDatabase.object('/medicos/' + medico.uid).set(medico).then(
      async (data) => {
        await this.crear(medico, user, persona)
      },
      (error) => {
        alert('error al crear medico');
      }
    );
  }

  update(medico: Medico,medicos: any, user: User,users :any) {
    this.actualizar(medicos, users)
    this.angularFireDatabase.object('/users/' + user.uid).set(user);
    return this.angularFireDatabase.object('/medicos/' + medico.uid).set(medico);
  }

  getCronograma(uid: string) {
    return this.angularFireDatabase.list('/cronograma/' + uid);
  }
  setCronograma(uid: string, cronograma: any) {
    return this.angularFireDatabase.object('/cronograma/' + uid).set(cronograma);
  }
  getCronogramaDefault() {
    return this.angularFireDatabase.list('/cronograma/default');
  }
  setCronogramaDefault(cronograma: Horario[]) {
    return this.angularFireDatabase.object('/cronograma/default').set(cronograma);
  }
  getDisponibles(uid: any, fecha: Date) {
    const fechaDir = fecha.toLocaleDateString('fr-CA');
    this.getHorarioDia(uid, fecha);
    return this.angularFireDatabase.list(
      '/agendamedica/' + uid + '/fechas/' + fechaDir + '/horario',
      ref => ref
        .orderByChild('status')
        .equalTo('online')
    );
  }
  getHorarioDia(uid: any, fecha: Date) {
    const dia = fecha.getDay();
    const fechaDir = fecha.toLocaleDateString('fr-CA');
    const newHorario = [];
    this.angularFireDatabase.object('/agendamedica/' + uid + '/fechas/' + fechaDir).valueChanges().subscribe(
      (horario) => {
        if (horario == null) {
          this.getCronograma(uid).valueChanges().subscribe(
            (cronograma: any) => {
              if (cronograma) {
                cronograma.forEach(element => {
                  if (element && element[dia].status === 'online') {
                    const hora = element[dia];
                    hora.hora = element.hora;
                    newHorario[hora.hora] = hora;
                  }
                });
                this.setDisponibleFecha(uid, fechaDir, newHorario);
              }
            }
          );
        }
      }
    );
    return true;
  }
  getfechasDisponible(uid: any) {
    return this.angularFireDatabase.list('/medicos/' + uid + '/fechas');
  }
  setfechasDisponible(uid: any, fechas: any) {
    return this.angularFireDatabase.object('/agendamedica/' + uid + '/fechas').set(fechas);
  }
  setDisponible(uid: any, fecha: any, horario: any) {
    return this.angularFireDatabase.object('/agendamedica/' + uid + '/fechas/' + fecha + '/horario/' + horario.hora).set(horario);
  }
  setDisponibleFecha(uid: any, fecha: any, horario: any) {
    return this.angularFireDatabase.object('/agendamedica/' + uid + '/fechas/' + fecha + '/horario').set(horario);
  }

   async registra(medico: Medico, persona: Persona, user: User) {
     return await this.validarUser(medico, persona, user);
  }

  async validarUser(medico: Medico, persona: Persona, user: User) {
    return await this.userService.getByEmail(user.email).then(
      (data: any) => {
        this.validarUsertwo(data, medico, persona, user);
      }
    );
  }
  async validarUsertwo(data: any, medico: Medico, persona: Persona, user: User) {
    const password = '123456';

    if (data.length > 0) {
      medico.uid = data[0].uid;
      persona.uid = data[0].uid;

      return await this.existeMedico(medico, persona, user);
    } else {
      return await this.authenticationService.registerWithEmail(user.email, password).then(
       async (session) => {
          medico.uid = session.user.uid;
          persona.uid = session.user.uid;
          const userCreate = {
            uid: session.user.uid,
            email: user.email,
            provider: ['password']
          };
          this.userService.createUser(userCreate);
          await this.existeMedico(medico, persona, user);
          this.authenticationService.recoveryPassword(user.email);
        }
      ).catch(
        (error) => {
          alert(error.message);
        }
      );
    }
  }

  async existeMedico(medico: Medico, persona: Persona, user: User) {
    return await this.getById(medico.uid).valueChanges().subscribe(
      async (data) => {
        if (data) {

        } else {
          this.personasService.update(persona);
          await this.create(medico, user, persona);
        }
      }
    );
  }

  async crear(medico, user, persona) {

    let datospersona = {
      "apellidos": persona.apellidos,
      "fechaNacimiento": persona.fechaNacimiento,
      "nombres": persona.nombres,
      "numeroDocumento": persona.numeroDocumento,
      "sexo": persona.sexo,
      "id_tipo_documento": persona.tipoDocumento
    }
    let idpersona;
    return await this.mspersonasService.crear(datospersona).then((response) => {
      idpersona = response.idPersona;
      let datosmedico = {
        "celular": medico.celular,
        "direccion": medico.direccion,
        "email": user.email,
        "estado": medico.estado == true ? 1 : 0,
        "firma": medico.firma,
        "id_especialidad": medico.idEspecialidad,
        "id_persona": idpersona,
        "imagen": medico.imagen,
        "nombre": medico.nombre,
        "nroColegio": medico.nroColegio,
        "precio": medico.precio,
        "rne": medico.rne,
        "texto": medico.texto,
        "uid": medico.uid
      }
      this.SmedicoService.crear(datosmedico).then((response) => {
        let datosuser = {
          "email": user.email,
          "name": medico.nombre,
          "roles": [{
            id: 2,
            name: "MEDICO"
          }],
          "uid": medico.uid,
          "username": user.email
        }
        this.msuserService.crear(datosuser).then((response) => {
        });
      });
    });
  }

  async actualizar(medico, user) {
    console.info("medico - update", medico)
    console.info("user - update", user)
     /*let datosmedico = {
      "celular": medico.celular,
      "direccion": medico.direccion,
      "email": user.email,
      "estado": medico.estado == true ? 1 : 0,
      "firma": medico.firma,
      "idMedico": medico.idMedico,
      "id_especialidad": medico.idEspecialidad,
      "id_persona": medico.idPersona,
      "imagen": medico.imagen,
      "nombre": medico.nombre,
      "nroColegio": medico.nroColegio,
      "precio": medico.precio,
      "rne": medico.rne,
      "texto": medico.texto,
      "uid": medico.uid
    }*/
    this.msuserService.actualizar(user);
    return await this.http.put<any>(apiServer + 'medico/' + medico.idMedico, medico).toPromise()
  }

}