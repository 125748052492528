import { Injectable } from '@angular/core';
import { User } from '../shared/interfaces/user';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  async getByEmail(email: string) {
    return await this.angularFireDatabase.list('/users', ref => ref.orderByChild('email').equalTo(email));
  }

  constructor(private angularFireDatabase: AngularFireDatabase) {

   }
   getUsers(){
    return this.angularFireDatabase.list('/users');
   }
   getById(uid: any){
    return this.angularFireDatabase.object('/users/' + uid);
   }
   createUser(user: User){
    return this.angularFireDatabase.object('/users/' + user.uid).set(user);
   }
   editUser(user: User){
    return this.angularFireDatabase.object('/users/' + user.uid).set(user);
   }
   setAvatar(avatar: any, uid: any){
    return this.angularFireDatabase.object('/users/' + uid + '/avatar').set(avatar);
   }
   addCita(cita: any){
     this.angularFireDatabase.object('paciente/' + cita.pacienteId + '/citas/' + cita.citaId).set(cita);
     this.angularFireDatabase.object('atenciones/medico/' + cita.medicoId + '/fecha/' + cita.fechaCita + '/' + cita.hora).set(cita);
   }
}
