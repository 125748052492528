<mat-sidenav-container class="atencion-container">
  <mat-sidenav #sidenavPago mode="side" position="end" class="atencion-sidenav" [fixedInViewport]="isMobileMedico"
    [fixedTopGap]="0" [fixedBottomGap]="0">

    <div (click)="sidenavPago.toggle()" class="close_modal d-disable-close">X</div>
    <div (click)="sidenavPago.toggle()" class="close_modal m-disable-close">
      <p class="arrow-left justify-content-center align-items-center d-inline-flex mb-4">
        <span class="material-icons">keyboard_arrow_left</span> Atras
      </p>
    </div>
    <div class="container nav-right-atencion">
      <div class="row avatar-row">
        <div class="col-7 col-md-7 col-xl-7">
          <h1 class="titulo-medico"></h1>
        </div>
        <div class="col-5 col-md-5 col-xl-5">
          <!--          <button class="btn btn-outline-azul">Ver Horario</button>-->
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <form [formGroup]="formencuesta">
            <h3 class="titulo-p-personales">Preguntas y Repuestas</h3>
            <div class="form-row">
              <div class="form-group col-md-12 input-general">
                <label for="preguntas"></label>
                <textarea class="form-control" style="height: 700px;" formControlName='preguntas' id="preguntas" disabled></textarea>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-6">
          <button class="btn btn-spacing btn-black" (click)="sidenavPago.toggle()">Cerrar</button>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content style="margin-right: auto">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-12 col-xl-12 ">
          <h1 class="titulo-perfil">Reporte de Encuestas de atenciones</h1>
        </div>

        <div class="col-12 col-md-12 col-xl-12">
          <mat-form-field appearance="fill" [formGroup]="range">
            <mat-label>Fecha inicial</mat-label>
            <input matInput [matDatepicker]="dpini" formControlName="start" id="inifecha"
              (dateChange)="onSelectionChange()">
            <mat-datepicker-toggle matSuffix [for]="dpini"></mat-datepicker-toggle>
            <mat-datepicker #dpini></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" [formGroup]="range">
            <mat-label>Fecha final</mat-label>
            <input matInput [matDatepicker]="dpfin" formControlName="end" (dateChange)="onSelectionChange()">
            <mat-datepicker-toggle matSuffix [for]="dpfin"></mat-datepicker-toggle>
            <mat-datepicker #dpfin></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="fecha">
              <th mat-header-cell *matHeaderCellDef> Fecha de Encuesta</th>
              <td mat-cell *matCellDef="let element"> {{element.fecha | date : 'fullDate':'GMT':'es-PE'}} </td>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="paciente">
              <th mat-header-cell *matHeaderCellDef> Paciente</th>
              <td mat-cell *matCellDef="let element"> {{element.atencion.paciente.persona.nombres}}
                {{element.atencion.paciente.persona.apellidos}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="doctor">
              <th mat-header-cell *matHeaderCellDef> Doctor de la Atencion</th>
              <td mat-cell *matCellDef="let element"> {{element.atencion.medico.nombre}} </td>
            </ng-container>

            <!-- Weight Column -->


            <!-- Weight Column -->

            <ng-container matColumnDef="detalles">
              <th mat-header-cell *matHeaderCellDef> Preguntas y Repuestas</th>
              <td mat-cell *matCellDef="let element">
                <button class="btn btn-outline-azul" (click)="seleccionarEncuesta(element)">Ver detalle</button>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
          <button class="btn btn-outline-azul" (click)="exportExcel()">Export Excel</button>

        </div>


      </div>

    </div>
  </mat-sidenav-content>
</mat-sidenav-container>