import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private angularFireAuth: AngularFireAuth) {

   }
  loginWithEmail(email: string, password: string){
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }
  registerWithEmail(email: string, password: string){
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  getStatus(){
    return this.angularFireAuth.authState;
  }
  logOut(){
    return this.angularFireAuth.signOut();
  }

  authCuentaFacebook(){
    const provider = new auth.FacebookAuthProvider();
    return this.angularFireAuth.signInWithPopup(provider).then(result => {
      return result;
    });
  }
  authCuentaGoogle(){
    const provider = new auth.GoogleAuthProvider();
    return this.angularFireAuth.signInWithPopup(provider).then(result => {
      return result;
    });
  }
  recoveryPassword(email){
    auth().sendPasswordResetEmail(email).then(
      () => {
        alert('se envio un correo para cambiar su contraseña');
      }
    ).catch(
      (error) => {
        console.log(error);
      }
    );
  }
  getAuthRoot(password){
    if ( environment.rootPassword === password ) {
      localStorage.setItem(environment.rootPassword, '0');
      return true;
    }else{
      return false;
    }

  }
}
