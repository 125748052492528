import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MsempresaService {

  constructor(
    private http: HttpClient

  ) { }

  async listar(){
    return await this.http.get<any>(apiServer + 'empresa/page/0/9').toPromise()  
  }
  async actualizar(idEmpresa,empresa){
    return await this.http.put<any>(apiServer + 'empresa/'+idEmpresa,empresa).toPromise()  
  }
}
