import { Injectable } from '@angular/core';
import { Meeting } from '@services/model/zoom.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  constructor(
    public http:HttpClient
  ) { }

    getMeeting( meeting:Meeting):Observable<any>{
     const header = { Authorization: `Basic ${environment.zoom.token}`, 'Content-Type': 'application/x-www-form-urlencoded' };
     const options = {
        headers: header,
     };
     return this.http.post<any>(environment.zoom.meeting , meeting, options);
  }
}
