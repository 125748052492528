import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Paciente } from '../model/paciente.modelo';
import { Atencion } from '../model/atenciones.modelo';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {

  constructor(private angularFireDatabase: AngularFireDatabase) { }

  findByApellidos(apellidos){
    return this.angularFireDatabase.list('/personas', ref => ref.orderByChild('apellidos').startAt(apellidos).limitToFirst(100));
   }
  getAll(){
   return this.angularFireDatabase.list('/pacientes');
  }
  getById(uid: any){
   return this.angularFireDatabase.object('/pacientes/' + uid);
  }
  create(paciente: Paciente){
    return this.angularFireDatabase.object('/pacientes/' + paciente.uid).set(paciente);
  }
  update(paciente: Paciente){
    return this.angularFireDatabase.object('/pacientes/' + paciente.uid).set(paciente);
  }
  setInforme(paciente: Paciente, atencion: Atencion){
    return this.angularFireDatabase.object('/pacientes/' + paciente.uid + '/atenciones/' + atencion.uid).set(atencion);
  }
}
