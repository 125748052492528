<div class="close_modal text-right close_desktop cursor" (click)="closeDialog()"><span>CERRAR</span></div>
<h1 class="titulo-maestro text-center">Horario de médico</h1>
<p (click)="closeDialog()" mat-dialog-title class="arrow-left close_modal justify-content-center align-items-center d-inline-flex mb-4">
  <span class="material-icons">keyboard_arrow_left</span> Atras</p>
<mat-dialog-content class="dialog-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12">
        <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">
          <caption class="horario">Horario</caption>
          <ng-container matColumnDef="Lunes">
            <th mat-header-cell class="tabla-titulo" id="col1" *matHeaderCellDef> Lunes </th>
            <td mat-cell 
                  *matCellDef="let element" 
                  class="tabla-subtitulo" 
                  [ngClass]="{'b-verde' : element.Lunes.status === 'online' }"
                  (click)="eventoHorario(element.Lunes, $event)"> {{element.Lunes.hora}} </td>
          </ng-container>
          <ng-container matColumnDef="Martes">
            <th mat-header-cell class="tabla-titulo" id="col2" *matHeaderCellDef> Martes </th>
            <td mat-cell *matCellDef="let element"
                  class="tabla-subtitulo"
                  [ngClass]="{'b-verde' : element.Martes.status === 'online' }"
                  (click)="eventoHorario(element.Martes, $event)"> {{element.Martes.hora}} </td>
          </ng-container>
          <ng-container matColumnDef="Miercoles">
            <th mat-header-cell class="tabla-titulo" id="col3" *matHeaderCellDef> Miercoles </th>
            <td mat-cell *matCellDef="let element" 
                  class="tabla-subtitulo"
                  [ngClass]="{'b-verde' : element.Miercoles.status === 'online' }"
                  (click)="eventoHorario(element.Miercoles, $event)"> {{element.Miercoles.hora}} </td>
          </ng-container>
          <ng-container matColumnDef="Jueves">
            <th mat-header-cell class="tabla-titulo" id="col4" *matHeaderCellDef> Jueves </th>
            <td mat-cell *matCellDef="let element"
                  class="tabla-subtitulo" 
                  [ngClass]="{'b-verde' : element.Jueves.status === 'online' }"
                  (click)="eventoHorario(element.Jueves, $event)"> {{element.Jueves.hora}} </td>
          </ng-container>
          <ng-container matColumnDef="Viernes">
            <th mat-header-cell class="tabla-titulo" id="col5" *matHeaderCellDef> Viernes </th>
            <td mat-cell *matCellDef="let element"
                  class="tabla-subtitulo" 
                  [ngClass]="{'b-verde' : element.Viernes.status === 'online' }"
                  (click)="eventoHorario(element.Viernes, $event)"> {{element.Viernes.hora}} </td>
          </ng-container>
          <ng-container matColumnDef="Sabado">
            <th mat-header-cell class="tabla-titulo" id="col6" *matHeaderCellDef> Sabado </th>
            <td mat-cell *matCellDef="let element"
                  class="tabla-subtitulo" 
                  [ngClass]="{'b-verde' : element.Sabado.status === 'online' }"
                  (click)="eventoHorario(element.Sabado, $event)"> {{element.Sabado.hora}} </td>
          </ng-container>
          <ng-container matColumnDef="Domingo">
            <th mat-header-cell class="tabla-titulo" id="col7" *matHeaderCellDef> Domingo </th>
            <td mat-cell *matCellDef="let element"
                  class="tabla-subtitulo" 
                  [ngClass]="{'b-verde' : element.Domingo.status === 'online' }"
                  (click)="eventoHorario(element.Domingo, $event)"> {{element.Domingo.hora}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="dialog-float">
  <button class="btn btn-red mt-2"  [mat-dialog-close]="true" (click)="guardar()">Guardar cambios</button>
</mat-dialog-actions>


