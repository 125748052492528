import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiServer } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MsatencionService {

  constructor(
    private http: HttpClient
  ) { }
  async listarxuidmedico(uid){
    return await this.http.get<any>(apiServer + 'atencion/atencionesmedicouid/' + uid + '/0/9999').toPromise()
  }
  async listar(){
    return await this.http.get<any>(apiServer + 'atencion/page/0/9999').toPromise()  
  }
  async actualizarvalidado(validado,id){
    return await this.http.get<any>(apiServer + 'atencion/updatevalidadoatencion/'+validado+'/'+id).toPromise()  
  }
  async listarfechainifin(fechaini,fechafin){
    return await this.http.get<any>(apiServer + 'atencion/atencionesmedico/'+fechaini+'/'+fechafin+'/0/9999').toPromise()  
  }
  async actualizarestado(estado,id){
    return await this.http.get<any>(apiServer + 'atencion/updateestadoatencion/'+estado+'/'+id).toPromise()
  }
  async actualizar(atencion){
    return await this.http.put<any>(apiServer + 'atencion/'+atencion.idAtencion,atencion).toPromise()
  }
}
