import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { TipoAtencion } from '@services/model/tipoatencion.modelo';

@Injectable({
  providedIn: 'root'
})
export class TipoAtencionService {

  constructor(private angularFireDatabase: AngularFireDatabase) { }

  getAll(){
   return this.angularFireDatabase.list('/tipoatencion');
  }
  getById(uid: string){
   return this.angularFireDatabase.object('/tipoatencion/' + uid);
  }
  push(tipoatencion: TipoAtencion){
    return this.angularFireDatabase.object('/tipoatencion/' + tipoatencion.uid).set(tipoatencion);
  }
}
